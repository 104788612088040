<template>
  <div :class="['form-input', disabled ? 'disabled' : '']">
    <label v-if="label">{{ label }}</label>
    <div :class="['input', customClass, error ? 'error' : '']">
      <input
        :maxlength="max"
        :value="model"
        class="input-password"
        :type="showPassword ? 'text' : 'password'"
        :disabled="disabled"
        :placeholder="placeholder"
        :inputmode="inputmode"
        :pattern="pattern"
        @input="handleInput"
        @keypress="onlyAllowNumbers"
      />
      <button
        v-if="canShowPassword"
        class="show-password"
        :disabled="disabled"
        @click="showPassword = !showPassword"
      >
        <EyeOpen
          v-if="!showPassword"
          class="eye-icon"
        />
        <EyeClosed
          v-else
          class="eye-icon"
        />
      </button>
    </div>
  </div>
</template>

<script>
  import EyeOpen from '@/assets/icons/eye-open.svg';
  import EyeClosed from '@/assets/icons/eye-close.svg';
  export default {
    components: { EyeOpen, EyeClosed },
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'password'
      },
      customClass: {
        type: String,
        default: ''
      },
      error: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      canShowPassword: {
        type: Boolean,
        default: false
      },
      model: {
        type: String,
        default: ''
      },
      max: {
        type: Number,
        default: 99
      },
      isNumeric: {
        type: Boolean,
        default: false
      },
      inputmode: {
        type: String,
        default: ''
      },
      pattern: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      showPassword: false
    }),
    methods: {
      handleInput(e) {
        const { value } = e.target;
        this.$emit('update:model', value);
      },
      onlyAllowNumbers(e) {
        if (this.isNumeric) {
          const charCode = e.charCode;
          if (charCode < 48 || charCode > 57) {
            e.preventDefault();
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  label {
    margin-bottom: 4px;
    color: #fff;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    width: 100%;
    text-align: left;
  }
  .eye-icon {
    width: 14px;
    height: 14px;
    margin: 0 auto;
  }
  .input {
    width: 100%;
    background: transparent;
    border-radius: 5px;
    border: 1px solid #998f8a;
    outline: 0;

    display: flex;
    flex-direction: row;
    gap: 14px;

    &:focus-within {
      border: 1px solid #974900 !important;
    }
  }

  .input-password {
    flex: 1;
    background: transparent;
    outline: 0;
    border: 0;
    height: 100%;
    width: 100%;

    padding: 9px 14px;
    font-variant-numeric: lining-nums proportional-nums;
    color: #fff;
  }

  .show-password {
    width: 42px;
    background: transparent;
    outline: 0;
    border: 0;

    svg path {
      fill: #fff;
    }
  }

  .error {
    border: 1px solid #ef5350;
    background: rgba(151, 73, 0, 0.05);
  }

  .disabled {
    filter: opacity(0.3) !important;
    div,
    label,
    input,
    button {
      cursor: not-allowed;
      user-select: none;
    }
  }

  @media (max-width: 480px) {
    input {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
</style>
