<template>
  <button
    type="button"
    :class="[
      'button-full-width__container',
      buttonColor === 'brown' ? '--btn-brown' : '--btn-white'
    ]"
    @click="$emit('click')"
    :disabled="disabled"
  >
    <div class="button-full-width__content">
      <slot name="icon" />

      <slot name="text" />
    </div>
  </button>
</template>

<script>
  export default {
    name: 'ButtonFullWidth',
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      buttonColor: {
        type: String,
        default: 'brown'
      }
    }
  };
</script>

<style lang="scss" scoped>
  .button-full-width__container {
    display: flex;
    width: 100%;
    padding: 12px 28px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 5px;
    outline: none;
    border: 1px solid #974900;
    user-select: none;
    cursor: pointer;

    &:disabled {
      border: 1px solid #ece0db !important;
      background: #ece0db !important;

      .button-full-width__content {
        div {
          color: #7e7570 !important;
        }
      }
    }

    &.--btn-brown {
      background: #974900;

      &:hover {
        background: #9f5714;
      }

      &:active {
        background: #a45f1f;
      }

      .button-full-width__content {
        div {
          color: #fff;
        }
      }
    }

    &.--btn-white {
      background: #fff;

      &:hover {
        background: #ffede2;
      }

      &:active {
        background: #ffdbc4;
      }

      .button-full-width__content {
        div {
          color: #974900;
        }
      }
    }

    .button-full-width__content {
      display: flex;
      align-items: center;
      gap: 12px;

      div {
        text-align: center;
        font-size: 17px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.213px;
      }
    }
  }

  @media (max-width: 485px) {
    .button-full-width__container {
      padding: 8px 21px;

      .button-full-width__content {
        div {
          font-size: 14px !important;
          line-height: 20px !important;
          letter-spacing: 0.175px !important;
        }
      }
    }
  }
</style>
