<template>
  <h4 class="tmf__process-description">
    {{ modalDescription }} <b>{{ email }}</b>
  </h4>
</template>

<script>
  export default {
    name: 'ModalTemplateForgotDescription',
    props: {
      modalDescription: {
        type: String,
        default: ''
      },
      email: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss" scoped>
  .tmf__process-description {
    color: #cfc4be;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin: 8px 0 24px;

    b {
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      color: #fff;
    }
  }

  @media (max-width: 485px) {
    .tmf__process-description {
      font-size: 12px;
      line-height: 16px;

      b {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
</style>
