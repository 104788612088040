<template>
  <!-- Full size variant -->
  <div
    v-if="variant === 'full'"
    :id="id"
    :class="['nav-item-full', open ? 'open' : '']"
    @click="handleClickFull"
  >
    <div class="content">
      <div class="icon-full">
        <slot name="icon" />
      </div>
      <span>{{ name }}</span>
    </div>
    <div
      v-if="dropdown"
      :class="['dropdown', open ? 'flipped' : '']"
    >
      <div class="icon-dropdown">
        <ArrowRight class="icon-dropdown" />
      </div>
    </div>
  </div>

  <!-- Compacted size variant -->
  <div
    v-else
    :id="id"
    class="nav-item-short"
    @click="$emit('click')"
  >
    <div class="icon-short">
      <slot name="icon" />
    </div>
  </div>
</template>

<script>
  import ArrowRight from '@/assets/icons/arrow-right.svg';
  export default {
    components: { ArrowRight },
    props: {
      id: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        required: true
      },
      variant: {
        type: String,
        required: true
      },
      dropdown: {
        type: Boolean,
        default: false
      },
      open: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleClickFull() {
        this.$emit('update:open', !this.open);
        this.$emit('click');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .flipped {
    transform: rotate(90deg);
    transform-origin: center;
  }
  .icon-dropdown {
    width: 16px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-full,
  .icon-short {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
  }
  .icon-full {
    svg {
      width: 14px;
      height: 14px;
    }
  }
  .icon-short {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .nav-item-full {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 8px 16px;
    gap: 8px;
    justify-content: space-between;

    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    cursor: pointer;
    user-select: none;

    &:hover {
      border-bottom: 1px solid #974900;
      border-top: 1px solid #974900;
      background: rgba(151, 73, 0, 0.05);

      span {
        color: #fff;
      }
    }

    &:active,
    &.open {
      border-top: 1px solid #974900;
      border-bottom: 1px solid #974900;
      background: rgba(151, 73, 0, 0.16);

      span {
        color: #fff;
      }
    }

    span {
      font-family: Raleway;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex: 1;
      color: #cfc4be;
    }

    .content {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }

  .nav-item-short {
    padding: 6px 12px;
    border-radius: 5px;
    max-width: max-content;

    cursor: pointer;
    border: 1px solid transparent;

    min-width: 48px;
    max-width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
    margin: 0 auto;

    &:hover {
      border: 1px solid #974900;
      background: rgba(151, 73, 0, 0.05);
    }

    &:active {
      border: 1px solid #974900;
      background: rgba(151, 73, 0, 0.16);
    }

    &.selected {
      background: #974900;
    }
  }
</style>
