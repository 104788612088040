<template>
  <b-tooltip
    :target="target"
    :triggers="triggers"
    :placement="placement"
    :custom-class="{
      'tooltip-default': true,
      'place-top': placement == 'top',
      'place-right': placement == 'right',
      'place-bottom ': placement == 'bottom',
      'place-left': placement == 'left'
    }"
  >
    {{ text }}
  </b-tooltip>
</template>

<script>
  import { BTooltip } from 'bootstrap-vue';
  export default {
    name: 'TooltipDefault',
    components: { BTooltip },
    props: {
      target: {
        type: String,
        required: true
      },
      triggers: {
        type: String,
        default: 'hover'
      },
      placement: {
        type: String,
        default: 'right'
      },
      text: {
        type: String,
        required: true
      }
    }
  };
</script>

<style lang="scss">
  .tooltip-default {
    .tooltip-inner {
      background: #1a1c19 !important;
      padding: 8px !important;
      border-radius: 6px !important;
      color: #ffffff !important;

      font-family: Raleway;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 20px !important;
    }

    &.place-top {
      margin-bottom: 8px;

      .arrow::before {
        border-top-color: #1a1c19 !important;
      }
    }

    &.place-right {
      margin-left: 8px;

      .arrow::before {
        border-right-color: #1a1c19 !important;
      }
    }

    &.place-bottom {
      margin-bottom: 8px;

      .arrow::before {
        border-bottom-color: #1a1c19 !important;
      }
    }

    &.place-left {
      margin-right: 8px;

      .arrow::before {
        border-left-color: #1a1c19 !important;
      }
    }
  }
</style>
