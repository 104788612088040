<template>
  <h1 class="rlc__title">{{ text }}</h1>
</template>

<script>
  export default {
    name: 'ResentLinkCardTitle',
    props: {
      text: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style scoped>
  .rlc__title {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    margin: 8px 0 0 0;
    color: #4c4541;
  }

  @media (max-width: 485px) {
    .rlc__title {
      font-size: 22px !important;
      line-height: 30px !important;
    }
  }
</style>
