<template>
  <header id="fgc__header-c">
    <CardLayoutLogo />
    <CardLayoutTitle> Esqueceu sua senha? </CardLayoutTitle>
    <CardLayoutSubtitle>
      Informe seu e-mail para enviarmos as instruções para redefinir sua senha
    </CardLayoutSubtitle>
  </header>
</template>

<script>
  import CardLayoutLogo from '@/views/authentication/components/card-layout/components/indsiders-logo';
  import CardLayoutTitle from '@/views/authentication/components/card-layout/components/title';
  import CardLayoutSubtitle from '@/views/authentication/components/card-layout/components/subtitle';

  export default {
    name: 'ForgotCardHeader',
    components: {
      CardLayoutLogo,
      CardLayoutTitle,
      CardLayoutSubtitle
    }
  };
</script>

<style lang="scss" scoped>
  #fgc__header-c {
    width: 100%;
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;
  }
</style>
