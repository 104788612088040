export default {
  namespaced: true,
  state: {
    flagShowForgotCard: true,
    flagShowSentLinkCardWithButton: false
  },
  getters: {
    flagShowForgotCard: (state) => state.flagShowForgotCard,
    flagShowSentLinkCardWithButton: (state) => state.flagShowSentLinkCardWithButton
  },
  mutations: {
    UPDATE_FLAG_SHOW_FORGOT_CARD: (state, payload) => (state.flagShowForgotCard = payload),
    UPDATE_FLAG_SHOW_SENT_LINK_CARD_WITH_BUTTON: (state, payload) =>
      (state.flagShowSentLinkCardWithButton = payload)
  },
  actions: {
    resetForgotStore({ commit }) {
      commit('UPDATE_FLAG_SHOW_FORGOT_CARD', true);
      commit('UPDATE_FLAG_SHOW_SENT_LINK_CARD_WITH_BUTTON', false);
    },
    showSentLinkCardWithButton({ commit }) {
      commit('UPDATE_FLAG_SHOW_FORGOT_CARD', false);
      commit('UPDATE_FLAG_SHOW_SENT_LINK_CARD_WITH_BUTTON', true);
    },
    clearForgotStore({ commit }) {
      commit('UPDATE_FLAG_SHOW_FORGOT_CARD', true);
      commit('UPDATE_FLAG_SHOW_SENT_LINK_CARD_WITH_BUTTON', false);
    }
  }
};
