<template>
  <div id="navbar">
    <div class="side">
      <div
        class="toggle"
        @click="openFullscreenMenu"
      >
        <HamburgerIcon class="hamburger-icon" />
      </div>
      <div class="logo">
        <Logo class="logo-icon" />
      </div>
    </div>
    <NavSiteSelector />
    <div
      id="profile_trigger_navbar"
      class="profile"
      tabindex="0"
    >
      <div class="user">
        <h3>{{ user.name }}</h3>
        <h2>{{ user.role }}</h2>
      </div>
      <div class="picture">
        <img
          v-if="user.picture"
          :src="user.picture"
          alt="me"
        />
        <Placeholder
          v-else
          :text="user.abbreviation"
          size="38px"
        />
      </div>
      <div class="arrow-down">
        <ArrowDown class="arrow-down-icon" />
      </div>
    </div>
    <Popover
      :items="items"
      target="profile_trigger_navbar"
      :title="$t('Menu.Titles.Account')"
      placement="down"
      custom-position="navbar"
      triggers="focus"
    />
    <transition name="menumodal">
      <Menu
        v-if="open"
        wide
        @close="closeFullscreenMenu"
      />
    </transition>
  </div>
</template>

<script>
  import Logo from '@/assets/icons/logo-short.svg';
  import HamburgerIcon from '@/assets/icons/hamburger-icon.svg';
  import ArrowDown from '@/assets/icons/arrow-down.svg';
  import ProfileIcon from '@/assets/icons/profile-icon.svg';
  import ExitIcon from '@/assets/icons/exit-icon.svg';

  import Menu from '@/components/Menu/Sidebar/index.vue';
  import Popover from '@/components/Menu/Popover.vue';

  import Placeholder from '@/components/Menu/AvatarPlaceholder.vue';
  import NavSiteSelector from './components/NavbarSiteSelector.vue';

  import { mapGetters } from 'vuex';

  const ICONS = {
    ProfileIcon,
    ExitIcon
  };

  export default {
    name: 'NavbarApp',
    components: { NavSiteSelector, Logo, HamburgerIcon, Menu, ArrowDown, Popover, Placeholder },
    computed: {
      ...mapGetters('userStore', ['user']),
      items() {
        return [
          {
            title: 'Menu.Items.Account',
            icon: ICONS.ProfileIcon,
            click: () => this.redirect('account'),
            selected: this.$route.name === 'account'
          },
          {
            title: 'Menu.Items.Exit',
            icon: ICONS.ExitIcon,
            click: () => this._logout()
          }
        ];
      }
    },
    data() {
      return {
        open: false,
        ICONS
      };
    },
    methods: {
      openFullscreenMenu() {
        this.open = true;
      },
      closeFullscreenMenu() {
        this.open = false;
      },
      redirect(page) {
        if (this.$route.name === page) return;

        this.$router.push({ name: page }).catch(() => {});
      }
    }
  };
</script>

<style lang="scss">
  .menumodal-enter-active,
  .menumodal-leave-active {
    #menu-bar {
      transition: all 0.5s;
      margin-left: 0px !important;
    }
  }

  .menumodal-enter,
  .menumodal-leave-to {
    #menu-bar {
      margin-left: calc(-100vw) !important;
    }
  }
</style>

<style lang="scss" scoped>
  #navbar {
    width: 100%;
    height: 60px;
    padding: 0 29px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #4c4541;
    background: rgba(26, 28, 25, 0.5);

    &::before {
      width: 100%;
      height: 100%;
      -webkit-backdrop-filter: blur(25px);
      backdrop-filter: blur(25px);
      z-index: -1;
    }
  }

  .side {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 20px;

    .toggle {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 100%;
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 100%;
    }
  }

  .profile {
    cursor: pointer;
    border-left: 1px solid #4c4541;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    gap: 8px;

    .user {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      align-items: flex-end;
      width: 93px;

      h3,
      h2 {
        margin: 0;
        padding: 0;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100%);
      }

      h3 {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      h2 {
        color: #cfc4be;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .picture {
      height: 100%;
      display: flex;
      align-items: center;
    }

    img {
      width: 38px;
      height: 38px;
      border-radius: 100%;
      object-fit: cover;
    }

    .arrow-down {
      height: 100%;
      width: 16px;
      display: flex;
      align-items: center;
    }
  }

  .hamburger-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .logo-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
  }

  .arrow-down-icon {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 780px) {
    #navbar {
      position: fixed;
      top: 0;
      z-index: 5;
      background: rgba(26, 28, 25, 1);
    }
  }

  @media (max-width: 480px) {
    #navbar {
      padding: 0 16px !important;
    }
  }
</style>
