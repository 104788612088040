<template>
  <div></div>
</template>

<script>
  export default {
    created() {
      localStorage.clear();
      const domain = window.location.href.includes('indsiders.com');      
      this.$cookies.remove(
        "userInfo",
        null,
        domain ? "indsiders.com" : null
      );
      this.$router.push('/login').catch(() => {});
    }
  };
</script>

<style></style>
