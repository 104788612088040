export default {
  namespaced: true,
  state: {
    ruleStatus1: false,
    ruleStatus2: false,
    ruleStatus3: false,
    ruleStatus4: false,
    flagShowResetCard: false,
    flagShowRestoredPasswordCard: false,
    flagShowExpiredLinkResetPasswordCard: false,
    flagShowResentLinkResetPasswordCard: false
  },
  getters: {
    ruleStatus1: (state) => state.ruleStatus1,
    ruleStatus2: (state) => state.ruleStatus2,
    ruleStatus3: (state) => state.ruleStatus3,
    ruleStatus4: (state) => state.ruleStatus4,
    flagShowResetCard: (state) => state.flagShowResetCard,
    flagShowRestoredPasswordCard: (state) => state.flagShowRestoredPasswordCard,
    flagShowExpiredLinkResetPasswordCard: (state) => state.flagShowExpiredLinkResetPasswordCard,
    flagShowResentLinkResetPasswordCard: (state) => state.flagShowResentLinkResetPasswordCard
  },
  mutations: {
    UPDATE_RULE_STATUS_1: (state, payload) => (state.ruleStatus1 = payload),
    UPDATE_RULE_STATUS_2: (state, payload) => (state.ruleStatus2 = payload),
    UPDATE_RULE_STATUS_3: (state, payload) => (state.ruleStatus3 = payload),
    UPDATE_RULE_STATUS_4: (state, payload) => (state.ruleStatus4 = payload),
    UPDATE_FLAG_SHOW_RESET_CARD: (state, payload) => (state.flagShowResetCard = payload),
    UPDATE_FLAG_SHOW_RESTORED_PASSWORD_CARD: (state, payload) =>
      (state.flagShowRestoredPasswordCard = payload),
    UPDATE_FLAG_SHOW_EXPIRED_LINK_RESET_PASSWORD_CARD: (state, payload) =>
      (state.flagShowExpiredLinkResetPasswordCard = payload),
    UPDATE_FLAG_SHOW_RESENT_LINK_RESET_PASSWORD_CARD: (state, payload) =>
      (state.flagShowResentLinkResetPasswordCard = payload)
  },
  actions: {
    showResetCard({ commit }) {
      commit('UPDATE_FLAG_SHOW_RESET_CARD', true);
    },
    showRestoredPasswordCard({ commit }) {
      commit('UPDATE_FLAG_SHOW_RESET_CARD', false);
      commit('UPDATE_FLAG_SHOW_RESTORED_PASSWORD_CARD', true);
    },
    showExpiredLinkResetPasswordCard({ commit }) {
      commit('UPDATE_FLAG_SHOW_RESET_CARD', false);
      commit('UPDATE_FLAG_SHOW_EXPIRED_LINK_RESET_PASSWORD_CARD', true);
    },
    showResentLinkResetPasswordCard({ commit }) {
      commit('UPDATE_FLAG_SHOW_EXPIRED_LINK_RESET_PASSWORD_CARD', false);
      commit('UPDATE_FLAG_SHOW_RESENT_LINK_RESET_PASSWORD_CARD', true);
    },
    clearResetPasswordStore({ commit }) {
      commit('UPDATE_RULE_STATUS_1', false);
      commit('UPDATE_RULE_STATUS_2', false);
      commit('UPDATE_RULE_STATUS_3', false);
      commit('UPDATE_RULE_STATUS_4', false);
      commit('UPDATE_FLAG_SHOW_RESET_CARD', false);
      commit('UPDATE_FLAG_SHOW_RESTORED_PASSWORD_CARD', false);
      commit('UPDATE_FLAG_SHOW_RESENT_LINK_RESET_PASSWORD_CARD', false);
      commit('UPDATE_FLAG_SHOW_EXPIRED_LINK_RESET_PASSWORD_CARD', false);
    }
  }
};
