<template>
  <main id="login-page-container">
    <LoginCard />
  </main>
</template>

<script>
  import LoginCard from './components/login-card';

  import loginMixin from '@/mixins/login';

  export default {
    name: 'LoginView',
    mixins: [loginMixin],
    components: {
      LoginCard
    }
  };
</script>

<style lang="scss" scoped>
  #login-page-container {
    min-height: 100dvh;
    width: 100%;
    padding: 37px 18px;
    background-image: url('../../../assets/images/cubos.png');
    background-color: #362f2b;
    background-size: 175px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
