import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './redirects';
import './utils';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import ptBRMessages from '@/libs/i18n/locales/pt.json';
import enUSMessages from '@/libs/i18n/locales/en.json';
import Toast from 'vue-toastification';
import VueCookies from 'vue-cookies-reactive';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-toastification/dist/index.css';

Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);
Vue.use(Toast, {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 6000,
  transition: 'Vue-Toastification__fade'
});
Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: 'pt-BR',
  fallbackLocale: 'en-US',
  messages: {
    'pt-BR': ptBRMessages,
    'en-US': enUSMessages
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
