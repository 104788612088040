import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Notification from '@/components/notification';

Vue.mixin({
  components: {
    Notification
  },
  computed: {
    ...mapGetters('appStore', {
      _flagShowLoading: 'flagShowLoading',
      _prefix: 'prefix'
    })
  },
  methods: {
    ...mapMutations('appStore', {
      _updatePrefix: 'UPDATE_PREFIX'
    }),
    ...mapActions('appStore', {
      _showLoading: 'showLoading',
      _hideLoading: 'hideLoading'
    }),
    _validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    _checkIfHasNumberInString(string) {
      const re = /\d/;
      return re.test(string);
    },
    _checkIfHasSpecialCharacterInString(string) {
      const re = /[!@#$%^&*(),.?":{}|<>]/;
      return re.test(string);
    },
    _checkIfIsNumberSequence(value) {
      const string = typeof value === Number ? value.toString() : value;
      const listNumber = string.split('').map(Number);
      const length = listNumber.length;

      let isCrescentSequence = true;
      let isDerescentSequence = true;

      if (length <= 1) return false;

      for (let i = 1; i < listNumber.length; i++) {
        if (listNumber[i] !== listNumber[i - 1] + 1) {
          isCrescentSequence = false;
          break;
        }
      }

      for (let i = 1; i < listNumber.length; i++) {
        if (listNumber[i] !== listNumber[i - 1] - 1) {
          isDerescentSequence = false;
          break;
        }
      }

      return isCrescentSequence || isDerescentSequence;
    },
    _preventIfKeyIsLetter(event) {
      const input = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'F5'];
      const keyPressed = event.key;
      const keyCode = event.keyCode ?? event.which;

      if (!input.includes(keyPressed) && keyCode != 8) event.preventDefault();
    },
    async _logout() {
      const path = `/api/${this._prefix}/logout`;
      this._showLoading();
      try {
        await this.$http.post(path);
        localStorage.clear();
        const domain = window.location.href.includes('indsiders.com');
        this.$cookies.remove(
          "userInfo",
          null,
          domain ? "indsiders.com" : null
        );
      } catch (error) {
        console.log({ error });
      } finally {
        this.$router.push({ name: 'login' }).catch(() => {});
        this._hideLoading();
      }
    },
    _showToast(title, icon, text, variant) {
      this.$toast({
        component: Notification,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant
        }
      });
    }
  }
});
