<template>
  <LoginPageLayoutCard>
    <ResetPinCardHeader />
    <resetPinCardForm />
  </LoginPageLayoutCard>
</template>

<script>
  import LoginPageLayoutCard from '@/views/authentication/components/card-layout';
  import ResetPinCardHeader from './components/header';
  import resetPinCardForm from './components/form';

  export default {
    name: 'ResetCard',
    components: {
      LoginPageLayoutCard,
      ResetPinCardHeader,
      resetPinCardForm
    }
  };
</script>

<style lang="scss" scoped></style>
