<template>
  <CardLayout>
    <ResentLinkCardHeader
      :card-icon="cardIcon"
      :card-title="cardTitle"
      :card-subtitle="cardSubtitle"
    />

    <ResentLinkCardContent :email="email" />
  </CardLayout>
</template>

<script>
  import CardLayout from '../card-layout';
  import ResentLinkCardHeader from './components/header';
  import ResentLinkCardContent from './components/content';

  export default {
    name: 'ResentLinkCard',
    components: {
      CardLayout,
      ResentLinkCardHeader,
      ResentLinkCardContent
    },
    props: {
      cardIcon: {
        type: String,
        default: ''
      },
      cardTitle: {
        type: String,
        default: '',
        required: true
      },
      cardSubtitle: {
        type: String,
        default: '',
        required: true
      },
      email: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss" scoped></style>
