<template>
  <h3 class="tmf__title-modal">{{ modalTitle }}</h3>
</template>

<script>
  export default {
    name: 'ModalTemplateForgotTitle',
    props: {
      modalTitle: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss" scoped>
  .tmf__title-modal {
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;
    margin: 0 !important;
  }

  @media (max-width: 485px) {
    .tmf__title-modal {
      font-size: 16px;
      line-height: 26px;
    }
  }
</style>
