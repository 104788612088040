<template>
  <!-- Full size variant -->
  <div
    v-if="variant === 'full'"
    :class="['nav-item-full', selected ? 'selected' : '']"
    @click="$emit('click')"
  >
    <div class="icon-full">
      <component :is="icon" />
    </div>
    <span>{{ name }}</span>
  </div>

  <!-- Compacted size variant -->
  <div v-else>
    <div
      :id="name.toLowerCase()"
      :class="['nav-item-short', selected ? 'selected' : '']"
      @click="$emit('click')"
    >
      <div class="icon-short">
        <component :is="icon" />
      </div>
    </div>
    <Tooltip
      :target="name.toLowerCase()"
      :text="name"
    />
  </div>
</template>

<script>
  import Tooltip from '@/components/Menu/Tooltip.vue';
  export default {
    components: { Tooltip },
    props: {
      name: {
        type: String,
        required: true
      },
      variant: {
        type: String,
        required: true
      },
      selected: {
        type: Boolean,
        default: false
      },
      icon: {
        type: Object,
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  .icon-full,
  .icon-short {
    display: flex;
    justify-content: center;
    align-items: center;
    svg path {
      fill: #cfc4be !important;
    }
  }
  .icon-full {
    svg {
      width: 14px;
      height: 14px;
    }
  }
  .icon-short {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .nav-item-full {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 8px 16px;
    gap: 8px;

    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    cursor: pointer;
    user-select: none;

    &:hover {
      border-bottom: 1px solid #974900;
      border-top: 1px solid #974900;
      background: rgba(151, 73, 0, 0.05);

      span {
        color: #fff;
      }

      svg path {
        fill: #fff !important;
      }
    }

    &:active {
      border-top: 1px solid #974900;
      border-bottom: 1px solid #974900;
      background: rgba(151, 73, 0, 0.16);

      span {
        color: #fff;
      }

      svg path {
        fill: #fff !important;
      }
    }

    &.selected {
      background: #974900;
      span {
        color: #fff;
      }
      svg path {
        fill: #fff !important;
      }
    }

    span {
      font-family: Raleway;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex: 1;
      color: #cfc4be;
    }
  }

  .nav-item-short {
    padding: 6px 12px;
    border-radius: 5px;
    max-width: max-content;

    cursor: pointer;
    border: 1px solid transparent;

    min-width: 48px;
    max-width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
    margin: 0 auto;

    &:hover {
      border: 1px solid #974900;
      background: rgba(151, 73, 0, 0.05);
      svg path {
        fill: #fff !important;
      }
    }

    &:active {
      border: 1px solid #974900;
      background: rgba(151, 73, 0, 0.16);
      svg path {
        fill: #fff !important;
      }
    }

    &.selected {
      background: #974900;
      svg path {
        fill: #fff !important;
      }
    }
  }
</style>
