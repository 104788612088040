<template>
  <div id="fgc__form-w">
    <div id="fgc__form-c">
      <ValidationMessage v-show="forgotState === false">
        Por favor, insira um e-mail válido
      </ValidationMessage>

      <InputText
        :vmodel.sync="email"
        field-label="E-mail"
        field-placeholder="Digite seu e-mail"
        :state="forgotState"
      />
    </div>

    <div id="fgc__buttons-c">
      <ButtonFullWidth
        :disabled="disabledButtons"
        @click="sendEmailForForgotPassword"
      >
        <div slot="text">Enviar Link</div>
      </ButtonFullWidth>

      <ButtonFullWidth
        :disabled="disabledButtons"
        button-color="white"
        @click="redirectToLoginPage"
      >
        <div slot="text">Voltar ao Login</div>
      </ButtonFullWidth>
    </div>
  </div>
</template>

<script>
  import ValidationMessage from '@/views/authentication/components/card-layout/components/validation-message';
  import InputText from '@/components/input-text';
  import ButtonFullWidth from '@/components/buttons/button-full-width';

  import forgotMixin from '@/mixins/forgot';

  export default {
    name: 'ForgotCardForm',
    components: {
      InputText,
      ButtonFullWidth,
      ValidationMessage
    },
    mixins: [forgotMixin],
    data: () => ({
      email: '',
      forgotState: null,
      disabledButtons: false
    }),
    mounted() {
      this.setEventListenerKeydown();
    },
    methods: {
      validateField() {
        const validation = !this.email || !this._validateEmail(this.email) ? false : null;
        this.forgotState = validation;

        return validation === null;
      },
      async sendEmailForForgotPassword() {
        const validation = this.validateField();
        if (!validation) return;

        const splitedEmail = this.email.split('@');
        const prefix = splitedEmail[1].split('.')[0];

        this._showLoading();

        const { ip } = await fetch('https://api.ipify.org/?format=json')
          .then((res) => res.json())
          .catch(() => ({ ip: '' }));

        const payload = {
          email: this.email,
          ip
        };

        this.disabledButtons = true;

        this.$http
          .post(`/api/${prefix}/reset`, payload)
          .then(() => {
            this._showSentLinkCardWithButton();
          })
          .catch(() => {
            this.forgotState = false;
          })
          .finally(() => {
            this.disabledButtons = false;
            this._hideLoading();
          });
      },
      redirectToLoginPage() {
        this._resetForgotStore();
        this.$router.push({ name: 'login' });
      },
      setEventListenerKeydown() {
        window.addEventListener('keydown', this.sendEmailPerKeydown);
      },
      removeEventListenerKeydown() {
        window.removeEventListener('keydown', this.sendEmailPerKeydown);
      },
      sendEmailPerKeydown({ key }) {
        key === 'Enter' && this.sendEmailForForgotPassword();
      }
    },
    destroyed() {
      this.removeEventListenerKeydown();
    }
  };
</script>

<style lang="scss" scoped>
  #fgc__form-w {
    width: 100%;
    border-top: 1px solid #eeeeee;

    #fgc__form-c {
      gap: 16px;
      margin: 16px 0 32px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    #fgc__buttons-c {
      gap: 16px 0;

      display: flex;
      flex-direction: column;
    }
  }
</style>
