import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('resetPinStore', {
      _ruleStatus1: 'ruleStatus1',
      _ruleStatus2: 'ruleStatus2',
      _ruleStatus3: 'ruleStatus3',
      _flagShowResetCard: 'flagShowResetCard',
      _flagShowRestoredPinCard: 'flagShowRestoredPinCard',
      _flagShowExpiredLinkResetPinCard: 'flagShowExpiredLinkResetPinCard',
      _flagShowResentLinkResetPinCard: 'flagShowResentLinkResetPinCard'
    })
  },
  methods: {
    ...mapMutations('resetPinStore', {
      _updateRuleStatus1: 'UPDATE_RULE_STATUS_1',
      _updateRuleStatus2: 'UPDATE_RULE_STATUS_2',
      _updateRuleStatus3: 'UPDATE_RULE_STATUS_3'
    }),
    ...mapActions('resetPinStore', {
      _showResetCard: 'showResetCard',
      _showRestoredPinCard: 'showRestoredPinCard',
      _showExpiredLinkResetPinCard: 'showExpiredLinkResetPinCard',
      _showResentLinkResetPinCard: 'showResentLinkResetPinCard',
      _clearResetPinStore: 'clearResetPinStore'
    })
  }
};
