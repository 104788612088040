<template>
  <div :class="['toastification', variant]">
    <div class="toast__container">
      <div class="col-icon">
        <IconSuccess v-if="variant == 'success'" />
        <IconError v-else-if="variant == 'danger'" />
        <IconAlertOutline v-else-if="icon === 'AlertOutline'" />
        <IconAlert v-else-if="variant == 'alert'" />
        <IconInfo v-else />
      </div>

      <div class="toast__content">
        <div class="toast__text-area">
          <span
            v-if="title"
            class="toast__title"
          >
            {{ title }}
          </span>

          <span
            v-if="text"
            class="toast__text"
          >
            {{ text }}
          </span>
        </div>
      </div>

      <div class="toast__close-button-c">
        <IconClose @click="$emit('close-toast')" />
      </div>
    </div>
  </div>
</template>

<script>
  import IconSuccess from '@/assets/icons/check-circle-icon.svg';
  import IconError from '@/assets/icons/cancel-circle-icon.svg';
  import IconInfo from '@/assets/icons/info-circle-icon.svg';
  import IconAlert from '@/assets/icons/alert-circle-icon.svg';
  import IconClose from '@/assets/icons/close-toast.svg';
  import IconAlertOutline from '@/assets/icons/alert-toastification-icon.svg';

  export default {
    components: {
      IconSuccess,
      IconError,
      IconInfo,
      IconAlert,
      IconClose,
      IconAlertOutline
    },
    props: {
      variant: {
        type: String,
        default: 'primary'
      },
      icon: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      text: {
        type: String,
        default: null
      },
      hideClose: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style></style>

<style lang="scss" scoped>
  .toastification {
    width: 380px;
    padding: 16px;
    margin: 0 !important;

    &.success {
      background-color: #66bb6a;
    }
    &.danger {
      background-color: #f44336;
    }
    &.alert {
      background-color: #ff9800;
    }
    &.info {
      background-color: #2196f3;
    }
    .toast__container {
      display: flex;

      .col-icon {
        margin-top: 2px;
      }

      .toast__close-button-c {
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .toast__content {
        width: 100%;
        .toast__text-area {
          margin-left: 5px;
          display: flex;
          flex-direction: column;

          .toast__title {
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            color: #ffffff;
          }

          .toast__text {
            color: #ffff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .toast__close-button-c {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 485px) {
    .toastification {
      width: 288px !important;
    }
  }
</style>
