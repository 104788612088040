<template>
  <h1 class="cdl__title">
    <slot />
  </h1>
</template>

<script>
  export default {
    name: 'ForgotCardTitle'
  };
</script>

<style lang="scss" scoped>
  .cdl__title {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;
    margin: 24px 0 0 0;
    color: #4c4541;
  }

  @media (max-width: 485px) {
    .cdl__title {
      font-size: 22px !important;
      line-height: 30px !important;
    }
  }
</style>
