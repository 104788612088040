<template>
  <header class="ntm__header-c">
    <NotificationCardIcon :icon="cardIcon" />
    <NotificationCardTitle :text="cardTitle" />
    <NotificationCardSubtitle :text="cardSubtitle" />
  </header>
</template>

<script>
  import NotificationCardIcon from './components/icon';
  import NotificationCardTitle from './components/title';
  import NotificationCardSubtitle from './components/subtitle';

  export default {
    name: 'NotificationCardHeader',
    components: {
      NotificationCardIcon,
      NotificationCardTitle,
      NotificationCardSubtitle
    },
    props: {
      cardIcon: {
        type: String,
        default: ''
      },
      cardTitle: {
        type: String,
        default: '',
        required: true
      },
      cardSubtitle: {
        type: String,
        default: '',
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ntm__header-c {
    width: 100%;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #eeeeee;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
