<template>
  <div id="rtp__form-w">
    <div id="rtp__form-c">
      <ValidationMessage v-show="resetState === false">
        Todos os requisitos devem ser cumpridos
      </ValidationMessage>

      <InputText
        :vmodel.sync="pin"
        field-label="Novo PIN"
        field-placeholder="Digite aqui seu novo PIN"
        field-type="password"
        :state="resetState"
        max-length="4"
        input-mode="numeric"
        @change="validatePin"
        @keydown="_preventIfKeyIsLetter($event)"
      />

      <InputText
        :vmodel.sync="retypePin"
        field-label="Repita o Novo PIN"
        field-placeholder="Redigite o novo PIN"
        field-type="password"
        :state="resetState"
        max-length="4"
        input-mode="numeric"
        @change="validatePin"
        @keydown="_preventIfKeyIsLetter($event)"
      />
    </div>

    <div id="rtp__buttons-c">
      <ButtonFullWidth
        :disabled="disabledButtons"
        @click="resetPin"
      >
        <div slot="text">Restaurar PIN</div>
      </ButtonFullWidth>
    </div>
  </div>
</template>

<script>
  import ValidationMessage from '@/views/authentication/components/card-layout/components/validation-message';
  import InputText from '@/components/input-text';
  import ButtonFullWidth from '@/components/buttons/button-full-width';

  import resetPinMixin from '@/mixins/reset-pin';

  export default {
    name: 'ResetPinCardForm',
    components: {
      InputText,
      ButtonFullWidth,
      ValidationMessage
    },
    mixins: [resetPinMixin],
    data: () => ({
      pin: '',
      retypePin: '',
      resetState: null,
      disabledButtons: false
    }),
    mounted() {
      this.setEventListenerKeydown();
    },
    destroyed() {
      this.removeEventListenerKeydown();
    },
    methods: {
      validatePin() {
        const rule_1 = this.pin.length === 4;
        const rule_2 = !!this.pin && !this._checkIfIsNumberSequence(this.pin);
        const rule_3 = this.pin === this.retypePin && !!this.pin;

        this._updateRuleStatus1(rule_1);
        this._updateRuleStatus2(rule_2);
        this._updateRuleStatus3(rule_3);

        this.resetState === false && this.validateFields();
      },
      validateFields() {
        const validation = this._ruleStatus1 && this._ruleStatus2 && this._ruleStatus3;
        this.resetState = validation ? null : false;

        return validation;
      },
      resetPin() {
        const validation = this.validateFields();

        if (!validation) return;

        const token = this.$router.currentRoute.params.token ?? '';
        const path = `/api/${this._prefix}/pin/update`;

        const payload = {
          pin: this.pin,
          code: token
        };

        this._showLoading();
        this.$http
          .put(path, payload)
          .then(() => {
            this._showRestoredPinCard();
          })
          .catch((error) => {
            const {
              response: {
                data: { email },
                status
              }
            } = error;

            if (status === 403 && !!email) {
              this._showExpiredLinkResetPinCard();
              return;
            }

            if (status === 403 && !email && !localStorage.getItem('userData')) {
              this.redirectToLoginPage();
              return;
            }

            if (status === 403 && !email && localStorage.getItem('userData')) {
              this.redirectToHomePage();
            }
          })
          .finally(() => {
            this._hideLoading();
          });
      },
      redirectToHomePage() {
        this.$router.push({ name: 'home', params: { prefix: this._prefix } }).catch(() => {});
      },
      redirectToLoginPage() {
        this.$router.push({ name: 'login' }).catch(() => {});
      },
      setEventListenerKeydown() {
        window.addEventListener('keydown', this.resetPinPerKeydown);
      },
      removeEventListenerKeydown() {
        window.removeEventListener('keydown', this.resetPinPerKeydown);
      },
      resetPinPerKeydown({ key }) {
        key === 'Enter' && this.resetPin();
      }
    }
  };
</script>

<style lang="scss" scoped>
  #rtp__form-w {
    width: 100%;
    border-top: 1px solid #eeeeee;

    #rtp__form-c {
      gap: 16px;
      margin: 16px 0 32px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    #rtp__buttons-c {
      gap: 16px 0;

      display: flex;
      flex-direction: column;
    }
  }
</style>
