import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('forgotStore', {
      _flagShowForgotCard: 'flagShowForgotCard',
      _flagShowSentLinkCardWithButton: 'flagShowSentLinkCardWithButton'
    })
  },
  methods: {
    ...mapActions('forgotStore', {
      _resetForgotStore: 'resetForgotStore',
      _showSentLinkCardWithButton: 'showSentLinkCardWithButton',
      _clearForgotStore: 'clearForgotStore'
    })
  }
};
