<template>
  <h2 class="ntm__subtitle">{{ text }}</h2>
</template>

<script>
  export default {
    name: 'NotificationCardSubtitle',
    props: {
      text: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ntm__subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 8px 0 0 0;
    color: #998f8a;
  }

  @media (max-width: 485px) {
    .ntm__subtitle {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
</style>
