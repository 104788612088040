<template>
  <div id="profile-form">
    <div class="title">
      <h2 class="account__title-text">{{ $t('Account.Profile.Title') }}</h2>
      <h6 class="account__subtitle-text">
        {{ $t('Account.Profile.Description') }}
      </h6>
    </div>
    <div class="profile-data">
      <div class="profile-data-item">
        <h4>{{ $t('Account.Profile.Fields.Name') }}</h4>
        <h3>{{ user.first_name || '-' }}</h3>
      </div>
      <div class="profile-data-item">
        <h4>{{ $t('Account.Profile.Fields.Surname') }}</h4>
        <h3>{{ user.last_name || '-' }}</h3>
      </div>
      <div class="profile-data-item">
        <h4>{{ $t('Account.Profile.Fields.Role') }}</h4>
        <h3>{{ user.role || '-' }}</h3>
      </div>
      <div class="profile-data-item">
        <h4>{{ $t('Account.Profile.Fields.Registration') }}</h4>
        <h3>{{ user.employee_id || '-' }}</h3>
      </div>
      <div class="profile-data-item">
        <h4>{{ $t('Account.Profile.Fields.Phone') }}</h4>
        <h3>{{ formatPhoneNumber(user?.cell_phone_number || '') || '-' }}</h3>
      </div>
      <div class="profile-data-item">
        <h4>{{ $t('Account.Profile.Fields.Email') }}</h4>
        <h3>{{ user.email || '-' }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    computed: {
      ...mapGetters('userStore', ['user'])
    },
    methods: {
      formatPhoneNumber(str) {
        const regex = /^(\d{2})(\d{5})(\d{4})$/;
        const phoneNumber = str.replace(regex, '($1) $2-$3');
        return phoneNumber;
      }
    }
  };
</script>

<style lang="scss" scoped>
  h2,
  h6 {
    margin-bottom: 8px;
  }
  #profile-form {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
  }
  .profile-data {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 16px 30px;
  }

  h3 {
    white-space: wrap;
  }

  .title {
    width: 40%;
  }

  .profile-data-item {
    width: calc(50% - 30px);
    h4 {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #998f8a;
      margin: 0 0 4px 0;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      text-align: left;
      color: #fff;
      margin: 0;
    }
  }

  @media (max-width: 1002px) {
    #profile-form {
      flex-wrap: wrap;
      gap: 24px;

      h6 {
        margin-bottom: 0;
      }
    }

    .title {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
    }

    h6 {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    .profile-data-item {
      width: 100%;
      h4 {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
      h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
</style>
