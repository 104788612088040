<template>
  <div id="photo-form">
    <div class="title">
      <h2 class="account__title-text">{{ $t('Account.Photo.Title') }}</h2>
      <h6 class="account__subtitle-text">
        {{ $t('Account.Photo.Description') }}
      </h6>
    </div>

    <div class="picture">
      <ErrorPopup
        v-if="showErrorPopup"
        custom-class="error-popup-photo"
        closable
        @close="closeErrorPopup"
      >
        {{ $t('Account.Errors.MaxLimit') }}
      </ErrorPopup>

      <div class="container">
        <div class="image">
          <img
            v-if="image || user.photo"
            :src="image || user.photo"
            alt="User profile picture"
          />
          <div
            v-else
            class="no-photo"
          >
            <span>{{ user.abbreviation }}</span>
          </div>
        </div>

        <div class="upload-container">
          <Upload
            accept="image/png, image/jpeg"
            @closeErrorPopup="closeErrorPopup"
            @openErrorPopup="openErrorPopup"
            @setImage="setProfilePicture"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Upload from '@/views/account/components/Upload.vue';
  import ErrorPopup from '@/views/account/components/ErrorPopup.vue';

  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'PhotoForm',
    components: { Upload, ErrorPopup },
    data: () => ({
      showErrorPopup: false
    }),
    methods: {
      ...mapActions('accountStore', ['setProfilePicture']),
      closeErrorPopup() {
        this.showErrorPopup = false;
      },
      openErrorPopup() {
        this.showErrorPopup = true;
      }
    },
    computed: {
      ...mapGetters('userStore', ['user']),
      ...mapGetters('accountStore', ['image'])
    }
  };
</script>

<style lang="scss" scoped>
  h2,
  h6 {
    margin-bottom: 8px;
  }
  .container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    flex: 1;
    gap: 30px;
    padding: 0;
    margin: 0;

    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 100%;
  }
  #photo-form {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
    flex-wrap: wrap;
  }

  .picture {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 16px 30px;
    flex-wrap: wrap;
  }

  .error-popup-photo {
    width: 100%;
  }

  .title {
    display: flex;
    flex-direction: column;
    max-width: 40%;
    width: 100%;

    min-height: 112px;
    align-self: flex-end;
  }
  .image {
    width: 112px;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    user-select: none;
    flex: 1;

    img,
    .no-photo {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      object-fit: cover;
    }

    .no-photo {
      background: #ffdbc4;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #512400;
        font-size: 36px;
        font-weight: 600;
        line-height: 50px;
      }
    }
  }

  .upload-container {
    width: 100%;
    height: unset;
  }

  h6 {
    margin: 0;
  }

  @media (max-width: 1002px) {
    #photo-form {
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 24px 30px;
    }

    .title {
      width: 100%;
      max-width: 100%;
      min-height: 0;
    }

    .image {
      padding: 16px;
      max-width: max-content;
      img {
        width: 80px;
        height: 80px;
      }
    }

    .upload-container {
      flex: 1;
      max-width: unset;
      width: -webkit-fill-available;
    }
  }

  @media (max-width: 480px) {
    .container {
      gap: 16px;
      flex-direction: column;
    }
    h2 {
      font-size: 16px;
      line-height: 26px;
    }
    h6 {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    #photo-form {
      gap: 16px;
    }

    .picture {
      flex-wrap: wrap;
      height: 100%;
    }

    .image {
      width: 100%;
      flex: auto;
      max-width: unset;
    }
  }
</style>
