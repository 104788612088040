<template>
  <div :class="['form-error-popup', customClass]">
    <slot />
    <button
      v-if="closable"
      @click="$emit('close')"
    >
      <CloseIcon class="close-icon" />
    </button>
  </div>
</template>

<script>
  import CloseIcon from '@/assets/icons/x-icon.svg';
  export default {
    components: { CloseIcon },
    props: {
      customClass: {
        type: String,
        default: ''
      },
      closable: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .form-error-popup {
    background: #ef5350;
    border-radius: 6px;
    padding: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
  }

  button {
    background: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    padding: 0;
  }

  .close-icon {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 480px) {
    .form-error-popup {
      gap: 16px;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;
    }
  }
</style>
