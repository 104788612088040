<template>
  <div class="cvm__message-c">
    <span class="cvm__message">
      <slot />
    </span>
  </div>
</template>

<script>
  export default {
    name: 'ValidationMessage'
  };
</script>

<style lang="scss" scoped>
  .cvm__message-c {
    background: #ffebee;
    border: 1px solid #b71c1c;
    border-radius: 6px;
    padding: 15px 16px;

    display: flex;
    align-items: center;
    align-self: stretch;

    .cvm__message {
      color: #b71c1c;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }

  @media (max-width: 485px) {
    .cvm__message-c {
      .cvm__message {
        font-size: 12px !important;
        line-height: 16px !important;
      }
    }
  }
</style>
