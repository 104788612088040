<template>
  <div
    v-resize="handleResize"
    class="support-page"
  >
    <div class="custom-container">
      <header>
        <span>
          {{ $t('SupportPage.Support') }}
        </span>

        <div class="divider"></div>
      </header>

      <div class="content">
        <div class="contact">
          <span class="title">
            {{ $t('SupportPage.NeedHelp') }}
          </span>

          <span class="sub-title">
            {{ $t('SupportPage.ContactUs') }}
          </span>

          <div class="iboxes">
            <div class="ibox-email">
              <email-icon class="icons" />

              <div class="info-ibox">
                <span class="title-ibox">
                  {{ $t('SupportPage.Email') }}
                </span>

                <span class="subtitle-ibox">
                  {{ $t('SupportPage.OurTeam') }}
                </span>

                <div class="line-ibox">
                  <span class="email-whatsapp">{{ email }}</span>

                  <div class="buttons">
                    <div
                      id="btn-open-email"
                      class="btn-actions"
                      @click="openEmail"
                    >
                      <open-icon />
                    </div>

                    <div
                      id="btn-copy-email"
                      class="btn-actions"
                      @click="
                        () => {
                          type = 'email';
                          copyToClipboard();
                        }
                      "
                    >
                      <copy-icon v-if="!copied || (type && type !== 'email')" />
                      <copied v-else-if="type === 'email'" />
                    </div>
                  </div>
                </div>
              </div>

              <tooltip
                v-if="windowWidth > 768"
                :text="
                  copied && type === 'email'
                    ? $t('SupportPage.Copied')
                    : $t('SupportPage.CopyEmail')
                "
                :target="'btn-copy-email'"
                :placement="'top'"
              />

              <tooltip
                v-if="windowWidth > 768"
                :text="$t('SupportPage.OpenEmail')"
                :target="'btn-open-email'"
                :placement="'top'"
              />
            </div>

            <div class="divider-iboxes"></div>

            <div class="ibox-whatsapp">
              <whats-icon class="icons" />

              <div class="info-ibox">
                <span class="title-ibox"> WhatsApp </span>

                <span class="subtitle-ibox">
                  {{ $t('SupportPage.MontoFri') }}
                </span>

                <div class="line-ibox">
                  <span class="email-whatsapp">{{ phone }}</span>

                  <div class="buttons">
                    <div
                      id="btn-open-whats"
                      class="btn-actions"
                      @click="openWhatsapp"
                    >
                      <open-icon />
                    </div>

                    <div
                      id="btn-copy-phone"
                      class="btn-actions"
                      @click="
                        () => {
                          type = 'phone';
                          copyToClipboard();
                        }
                      "
                    >
                      <copy-icon v-if="!copied || (type && type !== 'phone')" />
                      <copied v-else-if="type === 'phone'" />
                    </div>
                  </div>
                </div>
              </div>

              <tooltip
                v-if="windowWidth > 768"
                :text="
                  copied && type === 'phone'
                    ? $t('SupportPage.Copied')
                    : $t('SupportPage.CopyWhatsapp')
                "
                :target="'btn-copy-phone'"
                :placement="'top'"
              />

              <tooltip
                v-if="windowWidth > 768"
                :text="$t('SupportPage.OpenWhatsApp')"
                :target="'btn-open-whats'"
                :placement="'top'"
              />
            </div>
          </div>
        </div>

        <div class="image">
          <div
            class="support-image"
            :class="{ 'image-small': containerSize < 1059 }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EmailIcon from '@/assets/icons/email.svg';
  import OpenIcon from '@/assets/icons/open.svg';
  import CopyIcon from '@/assets/icons/copy.svg';
  import WhatsIcon from '@/assets/icons/whatsapp.svg';
  import Copied from '@/assets/icons/copied.svg';
  import resize from 'vue-resize-directive';
  import Tooltip from '@/components/tooltip';

  export default {
    name: 'SupportPage',
    components: {
      EmailIcon,
      OpenIcon,
      CopyIcon,
      WhatsIcon,
      Copied,
      // eslint-disable-next-line vue/no-unused-components
      Tooltip
    },
    directives: {
      resize
    },
    data() {
      return {
        containerSize: 1059,
        windowWidth: 0,
        copied: false,
        type: '',
        email: 'support@indsiders.com',
        phone: '+55 27 99640-5558'
      };
    },
    mounted() {
      this.resizeScreen();
      window.addEventListener('resize', this.resizeScreen);
    },
    destroyed() {
      window.removeEventListener('resize', this.resizeScreen);
    },
    methods: {
      resizeScreen() {
        this.windowWidth = window.innerWidth;
      },
      handleResize(e) {
        this.containerSize = e.scrollWidth;
      },
      formatPhoneNumber(value) {
        return value.replace(/\D/g, '');
      },
      async copyToClipboard() {
        if (!this.copied) {
          await this.handleClipboard();
          if (window.screen.width <= 768)
            this._showToast(
              this.$t('Success'),
              '',
              this.type === 'email'
                ? this.$t('SupportPage.CopiedEmail')
                : this.$t('SupportPage.CopiedWhatsapp'),
              'success'
            );

          setTimeout(() => {
            this.copied = false;
            this.type = '';
          }, 2000);
        }
      },
      async handleClipboard() {
        let clipboardValue =
          this.type === 'phone' ? this.formatPhoneNumber(this.phone) : this.email;

        const isNavigatorSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (isNavigatorSafari) {
          this.handleSafariClipboard(clipboardValue);
        } else {
          try {
            await navigator.clipboard.writeText(clipboardValue);
            this.copied = true;
          } catch (err) {
            // error handling
          }
        }
      },
      handleSafariClipboard(text) {
        const element = document.createElement('input');
        element.value = text;
        element.setAttribute('readonly', true); // Evita um bug visual no Safari/iPhone
        document.body.appendChild(element);
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
        this.copied = true;
      },
      openEmail() {
        window.location.href = `mailto:${this.email}`;
      },
      openWhatsapp() {
        window.open(`https://wa.me/${this.formatPhoneNumber(this.phone)}`, '_blank');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .support-page {
    padding: 24px 29px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .custom-container {
      max-width: 1059px;
      width: 100%;
    }

    header {
      text-align: left;
      width: 100%;

      span {
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        color: #ffffff;
      }

      .divider {
        height: 1px;
        background: #d9d9d9;
        margin-top: 4px;
      }
    }

    .content {
      display: flex;
      width: 100%;
      margin-top: 24px;
      gap: 30px;

      .contact {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 50%;

        .title {
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          color: #ffffff;
        }

        .sub-title {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          color: #cfc4be;
          margin-top: 8px;
        }

        .iboxes {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
        }

        .ibox-email {
          display: flex;
          padding: 16px 0px 16px 0px;
          border-top: 1px solid #4c4541;
          border-bottom: 1px solid #4c4541;
          gap: 16px;
        }

        .ibox-whatsapp {
          display: flex;
          padding: 16px 0px 16px 0px;
          border-bottom: 1px solid #4c4541;
          gap: 16px;
        }

        .icons {
          width: 24px;
          height: 24px;
        }

        .divider-iboxes {
          display: none;
          width: 1px;
          align-self: stretch;
          background: #4c4541;
        }

        .info-ibox {
          display: flex;
          flex-direction: column;
        }

        .title-ibox {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          color: #ffffff;
        }

        .subtitle-ibox {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          color: #cfc4be;
        }

        .line-ibox {
          display: flex;
          margin-top: 8px;
          align-items: center;
          gap: 16px;

          .email-whatsapp {
            min-width: 156px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: #ffffff;
            cursor: pointer;
          }

          .buttons {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .btn-actions {
              border-radius: 5px;
              background-color: #974900;
              display: flex;
              width: 28px;
              height: 28px;
              padding: 8px;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              &:hover {
                background-color: #9f5714;
              }

              &:active {
                background-color: #a45f1f;
              }
            }
          }
        }
      }

      .image {
        display: flex;
        justify-content: center;
        width: 50%;

        .support-image {
          background-image: url('~@/assets/images/support.png');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 531.802px;
        }

        .image-small {
          height: 454.204px;
        }
      }
    }
  }

  @media screen and (max-width: 1083px) {
    .support-page {
      .content {
        flex-direction: column;

        .contact {
          width: 100%;

          .iboxes {
            flex-direction: row;
            gap: 30px;
          }

          .ibox-email {
            border-top: none;
            border-bottom: none;
            width: 50%;
          }

          .ibox-whatsapp {
            border-bottom: none;
            width: 50%;
            justify-content: end;
          }

          .divider-iboxes {
            display: block;
          }
        }

        .image {
          width: 100%;

          .image-small {
            width: 439px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 951px) {
    .support-page {
      .content {
        .contact {
          width: 100%;

          .iboxes {
            flex-direction: column;
            gap: 0px;
          }

          .ibox-email {
            border-top: 1px solid #4c4541;
            border-bottom: 1px solid #4c4541;
            width: 100%;
          }

          .ibox-whatsapp {
            border-bottom: 1px solid #4c4541;
            width: 100%;
            justify-content: flex-start;
          }

          .divider-iboxes {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .support-page {
      padding: 20px 29px;

      .content {
        flex-direction: column;
        gap: 40px;

        .contact {
          width: 100%;

          .iboxes {
            flex-direction: row;
            gap: 30px;
          }

          .ibox-email {
            border-top: none;
            border-bottom: none;
            width: 50%;
          }

          .ibox-whatsapp {
            border-bottom: none;
            width: 50%;
          }

          .divider-iboxes {
            display: block;
          }
        }

        .image {
          width: 100%;

          .support-image {
            width: 508px;
            height: 525.594px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 670px) {
    .support-page {
      .content {
        .contact {
          .line-ibox {
            .email-whatsapp {
              min-width: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 624px) {
    .support-page {
      .content {
        .contact {
          .iboxes {
            flex-direction: column;
            gap: 0px;
          }

          .ibox-email {
            border-top: 1px solid #4c4541;
            border-bottom: 1px solid #4c4541;
            width: 100%;
          }

          .ibox-whatsapp {
            border-bottom: 1px solid #4c4541;
            width: 100%;
            justify-content: flex-start;
          }

          .divider-iboxes {
            display: none;
          }

          .line-ibox {
            .email-whatsapp {
              min-width: 156px;
            }
          }
        }

        .image {
          width: 100%;

          .support-image {
            width: 492px;
            height: 509.04px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .support-page {
      padding: 20px 16px;

      header {
        span {
          font-size: 22px;
          line-height: 30px;
        }

        .divider {
          height: 1px;
          background: #d9d9d9;
          margin-top: 4px;
        }
      }

      .content {
        gap: 24px;

        .contact {
          .ibox-email {
            padding: 8px 0px 8px 0px;
          }

          .ibox-whatsapp {
            padding: 8px 0px 8px 0px;
          }

          .title {
            font-size: 16px;
            line-height: 26px;
          }

          .sub-title {
            font-size: 12px;
            line-height: 16px;
          }

          .icons {
            width: 16px;
            height: 16px;
          }

          .title-ibox {
            font-size: 14px;
            line-height: 20px;
          }

          .subtitle-ibox {
            font-size: 12px;
            line-height: 16px;
          }

          .line-ibox {
            gap: 8px;
            .email-whatsapp {
              min-width: 134px;
              font-size: 12px;
              line-height: 16px;
            }
          }
        }

        .image {
          .support-image {
            width: 338.284px;
            height: 350px;
          }
        }
      }
    }
  }
</style>
