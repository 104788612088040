<template>
  <header id="rtp__header-c">
    <CardLayoutLogo />
    <CardLayoutTitle> Crie um novo PIN </CardLayoutTitle>
    <PinStatus />
  </header>
</template>

<script>
  import CardLayoutLogo from '@/views/authentication/components/card-layout/components/indsiders-logo';
  import CardLayoutTitle from '@/views/authentication/components/card-layout/components/title';
  import PinStatus from './components/pin-status';

  export default {
    name: 'ResetPinCardHeader',
    components: {
      CardLayoutLogo,
      CardLayoutTitle,
      PinStatus
    }
  };
</script>

<style lang="scss" scoped>
  #rtp__header-c {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
</style>
