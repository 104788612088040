<template>
  <div class="lgp__card-layout-c">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'LoginPageLayoutCard'
  };
</script>

<style lang="scss" scoped>
  .lgp__card-layout-c {
    width: 100vw;
    max-width: 527px;
    background: #fff;

    padding: 23px 23px;
    border-radius: 6px;
    border: 1px solid #1e3040;
    box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.2), 0px 5px 22px 0px rgba(0, 0, 0, 0.12),
      0px 12px 17px 0px rgba(0, 0, 0, 0.14);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  @media (max-width: 485px) {
    .lgp__card-layout-c {
      padding: 23px 15px !important;
    }
  }
</style>
