export default {
  namespaced: true,
  state: {
    fields: {
      password: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      pin: {
        currentPIN: '',
        newPIN: '',
        confirmNewPIN: ''
      },
      image: ''
    }
  },
  getters: {
    currentPassword: (s) => s.fields.password.currentPassword,
    newPassword: (s) => s.fields.password.newPassword,
    confirmNewPassword: (s) => s.fields.password.confirmNewPassword,
    currentPIN: (s) => s.fields.pin.currentPIN,
    newPIN: (s) => s.fields.pin.newPIN,
    confirmNewPIN: (s) => s.fields.pin.confirmNewPIN,
    image: (s) => s.fields.image
  },
  mutations: {
    SET_CURRENT_PASSWORD: (state, payload) => (state.fields.password.currentPassword = payload),
    SET_NEW_PASSWORD: (state, payload) => (state.fields.password.newPassword = payload),
    SET_CONFIRM_NEW_PASSWORD: (state, payload) =>
      (state.fields.password.confirmNewPassword = payload),
    SET_CURRENT_PIN: (state, payload) => (state.fields.pin.currentPIN = payload),
    SET_NEW_PIM: (state, payload) => (state.fields.pin.newPIN = payload),
    SET_CONFIRM_NEW_PIN: (state, payload) => (state.fields.pin.confirmNewPIN = payload),
    CLEAR_PASSWORD_FORM: (state) =>
      (state.fields.password = { currentPassword: '', newPassword: '', confirmNewPassword: '' }),
    CLEAR_PIN_FORM: (state) =>
      (state.fields.pin = { currentPIN: '', newPIN: '', confirmNewPIN: '' }),
    SET_IMAGE: (state, payload) => {
      state.fields.image = payload;
    },
    CLEAR_IMAGE(state) {
      state.fields.image = '';
    }
  },
  actions: {
    setCurrentPassword: ({ commit }, value) => {
      commit('SET_CURRENT_PASSWORD', value);
    },
    setNewPassword: ({ commit }, value) => {
      commit('SET_NEW_PASSWORD', value);
    },
    setConfirmNewPassword: ({ commit }, value) => {
      commit('SET_CONFIRM_NEW_PASSWORD', value);
    },
    setCurrentPIN: ({ commit }, value) => {
      commit('SET_CURRENT_PIN', value);
    },
    setNewPIN: ({ commit }, value) => {
      commit('SET_NEW_PIM', value);
    },
    setConfirmNewPIN: ({ commit }, value) => {
      commit('SET_CONFIRM_NEW_PIN', value);
    },
    clearPasswordForm({ commit }) {
      commit('CLEAR_PASSWORD_FORM');
    },
    clearPinForm({ commit }) {
      commit('CLEAR_PIN_FORM');
    },
    clearAll({ commit }) {
      commit('CLEAR_PIN_FORM');
      commit('CLEAR_PASSWORD_FORM');
      commit('CLEAR_IMAGE');
    },
    setProfilePicture({ commit }, payload) {
      commit('SET_IMAGE', payload);
    }
  }
};
