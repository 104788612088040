<template>
  <header id="rsp__header-c">
    <CardLayoutLogo />
    <CardLayoutTitle> Crie uma nova senha </CardLayoutTitle>
    <PasswordStatus />
  </header>
</template>

<script>
  import CardLayoutLogo from '@/views/authentication/components/card-layout/components/indsiders-logo';
  import CardLayoutTitle from '@/views/authentication/components/card-layout/components/title';
  import PasswordStatus from './components/password-status';

  export default {
    name: 'ResetPasswordCardHeader',
    components: {
      CardLayoutLogo,
      CardLayoutTitle,
      PasswordStatus
    }
  };
</script>

<style lang="scss" scoped>
  #rsp__header-c {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
</style>
