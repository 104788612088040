import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('resetPasswordStore', {
      _ruleStatus1: 'ruleStatus1',
      _ruleStatus2: 'ruleStatus2',
      _ruleStatus3: 'ruleStatus3',
      _ruleStatus4: 'ruleStatus4',
      _flagShowResetCard: 'flagShowResetCard',
      _flagShowRestoredPasswordCard: 'flagShowRestoredPasswordCard',
      _flagShowExpiredLinkResetPasswordCard: 'flagShowExpiredLinkResetPasswordCard',
      _flagShowResentLinkResetPasswordCard: 'flagShowResentLinkResetPasswordCard'
    })
  },
  methods: {
    ...mapMutations('resetPasswordStore', {
      _updateRuleStatus1: 'UPDATE_RULE_STATUS_1',
      _updateRuleStatus2: 'UPDATE_RULE_STATUS_2',
      _updateRuleStatus3: 'UPDATE_RULE_STATUS_3',
      _updateRuleStatus4: 'UPDATE_RULE_STATUS_4'
    }),
    ...mapActions('resetPasswordStore', {
      _showResetCard: 'showResetCard',
      _showRestoredPasswordCard: 'showRestoredPasswordCard',
      _showExpiredLinkResetPasswordCard: 'showExpiredLinkResetPasswordCard',
      _showResentLinkResetPasswordCard: 'showResentLinkResetPasswordCard',
      _clearResetPasswordStore: 'clearResetPasswordStore'
    })
  }
};
