<template>
  <header class="rlc__header-c">
    <ResentLinkCardIcon :icon="cardIcon" />
    <ResentLinkCardTitle :text="cardTitle" />
    <ResentLinkCardSubtitle :text="cardSubtitle" />
  </header>
</template>

<script>
  import ResentLinkCardIcon from './components/icon';
  import ResentLinkCardTitle from './components/title';
  import ResentLinkCardSubtitle from './components/subtitle';

  export default {
    name: 'ResentLinkCardHeader',
    components: {
      ResentLinkCardIcon,
      ResentLinkCardTitle,
      ResentLinkCardSubtitle
    },
    props: {
      cardIcon: {
        type: String,
        default: ''
      },
      cardTitle: {
        type: String,
        default: '',
        required: true
      },
      cardSubtitle: {
        type: String,
        default: '',
        required: true
      },
      hasButton: {
        type: Boolean,
        default: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  .rlc__header-c {
    width: 100%;
    margin-bottom: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
