<template>
  <div class="div-home">
    <div class="div-info-user">
      <span class="name-user">
        {{ `${$t('Home.Hello')}, ${userData.first_name}` }}
      </span>

      <b-carousel
        id="carousel-fade"
        fade
        indicators
        class="custom-carousel"
      >
        <b-carousel-slide>
          <template #img>
            <div class="carousel-text">{{ $t('Home.RotatingPhrases1') }}</div>
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <div class="carousel-text">{{ $t('Home.RotatingPhrases2') }}</div>
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <div class="carousel-text">{{ $t('Home.RotatingPhrases3') }}</div>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>

    <div class="div-products">
      <card-product
        v-for="(item, index) in products"
        :key="index"
        :data="item"
      >
      </card-product>
    </div>
  </div>
</template>

<script>
  import CardProduct from './components/CardProduct.vue';

  export default {
    name: 'HomeView',
    components: {
      CardProduct
    },
    data() {
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
        products: [
          { name: 'MySFC', info: 'Home.ExplanatoryTextMySfc', redirect: this.$mySfcLink },
          { name: 'MyDashboard', info: 'Home.ExplanatoryTextMyDashboard', redirect: '' },
          {
            name: 'MyConnect',
            info: 'Home.ExplanatoryTextMyConnect',
            redirect: this.$myConnectLink
          },
          { name: 'MyConfig', info: 'Home.ExplanatoryTextMyConfig', redirect: '' }
        ]
      };
    }
  };
</script>
<style lang="scss">
  #carousel-fade {
    z-index: 1 !important;
  }

  .div-home {
    display: flex;
    flex-direction: column;
    min-height: calc(100dvh - 68px);
    gap: 24px;

    .div-info-user {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      padding: 24px 29px 0px 29px;
      gap: 16px;

      .name-user {
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
        color: #fff;
      }

      .greeting {
        font-size: 28px;
        font-weight: 600;
        line-height: 38px;
        color: #fff;
      }

      .custom-carousel {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .carousel-indicators {
          position: initial !important;
          justify-content: flex-start !important;
          margin-left: 0 !important;
          margin-bottom: 0 !important;

          li {
            margin-right: 8px;
            margin-left: 0px;
            border: none;
            border-radius: 5px;
            background-color: #7e7570;

            &:hover {
              opacity: 1;
              background-color: #974900;
            }

            &.active {
              background-color: #ffffff;
            }
          }
        }
      }

      .carousel-text {
        text-align: start;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #cfc4be;
      }
    }

    .div-products {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      padding: 0px 29px;
      gap: 24px 30px;
    }
  }

  @media screen and (max-width: 1215px) {
    .div-home {
      .div-products {
        flex-wrap: wrap;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .div-home {
      .carousel-text {
        height: 44px;
      }

      .div-products {
        gap: 24px 16px;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .div-home {
      .div-info-user {
        padding: 24px 16px 0px 16px;

        .name-user {
          font-size: 14px;
          line-height: 20px;
        }

        .custom-carousel {
          gap: 16px;
        }

        .carousel-text {
          font-size: 12px;
          line-height: 16px;
          height: 32px;
        }
      }

      .div-products {
        padding: 0px 16px 24px 16px;
      }
    }
  }
</style>
