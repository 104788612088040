import Vue from 'vue';
import Vuex from 'vuex';
import appStore from './app';
import loginStore from './login';
import userStore from './user';
import forgotStore from './forgot';
import resetPasswordStore from './reset-password';
import resetPinStore from './reset-pin';
import accountStore from './account';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accountStore,
    appStore,
    loginStore,
    forgotStore,
    resetPasswordStore,
    resetPinStore,
    userStore
  },
  strict: false
});
