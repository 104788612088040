export default {
  namespaced: true,
  state: {
    user: {}
  },
  getters: {
    user: (state) => state.user
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = { ...payload };
    }
  },
  actions: {
    setUser(context, payload) {
      const { name } = payload;
      const abbreviation = (() => {
        const [first, last] = String(name).toUpperCase().trim().split(' ');
        return `${first[0]}${last[0]}`;
      })();

      context.commit('SET_USER', { ...payload, abbreviation });
    }
  }
};
