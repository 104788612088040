<template>
  <b-form-group
    v-if="input === 'text'"
    :class="['input-group__container', ...customClass]"
    :label="fieldLabel"
    :state="state"
    :invalid-feedback="invalidFeedback"
  >
    <div :class="['input__container', { '--focus': focus }]">
      <b-form-input
        :id="inputID"
        :type="fieldType"
        :class="['input__field']"
        :value="vmodel"
        @input="inputEvent($event)"
        :state="state"
        :placeholder="fieldPlaceholder"
        :maxlength="maxLength"
        :inputmode="inputMode ?? ''"
        @keydown="$emit('keydown', $event)"
        @focus="setFocus"
        @blur="setBlur"
      />
    </div>
  </b-form-group>

  <b-form-group
    v-else
    :class="['input-group__container', ...customClass]"
    :label="fieldLabel"
    :state="state"
    :invalid-feedback="invalidFeedback"
  >
    <div :class="['input__container', { '--focus': focus }]">
      <b-input-group class="d-flex">
        <b-form-input
          :id="inputID"
          :type="passwordInputFieldType"
          :class="['input__field']"
          :value="vmodel"
          @input="inputEvent($event)"
          :state="state"
          :placeholder="fieldPlaceholder"
          :maxlength="maxLength"
          :inputmode="inputMode ?? ''"
          @keydown="$emit('keydown', $event)"
          @focus="setFocus"
          @blur="setBlur"
        />

        <b-input-group-append class="input__append">
          <button
            class="password-eye__button"
            type="button"
            @click="changeEyeStatus"
          >
            <EyeOpenIcon v-show="eyeOpen" />
            <EyeCloseIcon v-show="!eyeOpen" />
          </button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </b-form-group>
</template>

<script>
  import { BFormInput, BFormGroup, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';
  import EyeOpenIcon from '@/assets/icons/eye-open.svg';
  import EyeCloseIcon from '@/assets/icons/eye-close.svg';

  export default {
    name: 'InputText',
    components: {
      EyeOpenIcon,
      EyeCloseIcon,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BInputGroupAppend
    },
    props: {
      vmodel: {
        type: String,
        default: '',
        required: true
      },
      state: {
        type: Boolean || null,
        default: null
      },
      fieldType: {
        type: String,
        default: 'text'
      },
      fieldPlaceholder: {
        type: String,
        default: ''
      },
      fieldLabel: {
        type: String,
        default: '',
        required: true
      },
      customClass: {
        type: Array,
        default: () => []
      },
      invalidFeedback: {
        type: String,
        default: ''
      },
      input: {
        type: String,
        default: 'text'
      },
      inputID: {
        type: String,
        default: ''
      },
      maxLength: {
        type: String,
        default: ''
      },
      inputMode: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      eyeOpen: false,
      focus: false
    }),
    computed: {
      passwordInputFieldType() {
        return this.eyeOpen ? 'text' : 'password';
      }
    },
    methods: {
      changeEyeStatus() {
        this.eyeOpen = !this.eyeOpen;
      },
      inputEvent(event) {
        this.$emit('update:vmodel', event);
        this.$emit('change', event);
      },
      setFocus() {
        this.focus = true;
      },
      setBlur() {
        this.focus = false;
      }
    }
  };
</script>

<style lang="scss">
  .input-group__container {
    margin: 0;

    input {
      height: 36px;
      background-image: none !important;
      border: none !important;
      box-shadow: none !important;
      outline: none;

      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }

    button {
      border: none !important;
    }

    legend {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      padding: 0;
    }

    .invalid-feedback {
      text-align: left;
    }

    &:has(.is-invalid) {
      .input__container {
        border-color: #d32f2f !important;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-background-clip: text;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px #fff;
    }
  }

  .input__container {
    display: flex;
    border: 1px solid #cfc4be;
    border-radius: 5px;
    margin-top: 4px;

    &.--focus {
      border-color: #974900 !important;
    }

    .input__field {
      display: flex;
      padding: 9px 14px;
      background: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #4c4541;

      &[type='password']::-ms-reveal,
      &[type='password']::-ms-clear {
        display: none !important;
      }

      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #cfc4be;
      }
    }

    .--border-right-none {
      border-right: none !important;
    }

    .input__append {
      .password-eye__button {
        background: transparent;
        border-left: none;
        padding: 0 14px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          height: 14px;
          width: 14px;

          path {
            fill: #cfc4be;
          }
        }
      }
    }
  }
</style>
