<template>
  <div id="pin-reset-form">
    <div class="title">
      <h3 class="account__title-text">{{ $t('Account.PINReset.Title') }}</h3>
      <h4 class="account__subtitle-text">
        {{ $t('Account.PINReset.Description') }}
      </h4>
      <ul>
        <ValidationItem
          :text="$t('Account.PINReset.Rules.FourNumbers')"
          :valid="hasFourNumbers"
        />
        <ValidationItem
          :text="$t('Account.PINReset.Rules.NotSequence')"
          :valid="isNotSequence"
        />
        <ValidationItem
          :text="$t('Account.PINReset.Rules.Equal')"
          :valid="pinsAreEqual"
        />
      </ul>
      <Button
        custom-class="forgot-pin-btn"
        @click="openModal"
        >{{ $t('Account.PINReset.ForgotPIN') }}</Button
      >
    </div>
    <div class="form">
      <ErrorPopup
        v-if="error"
        custom-class="error-popup"
        >{{ $t(`Account.Errors.${error}`) }}</ErrorPopup
      >
      <Input
        id="current-pin"
        :label="$t('Account.PINReset.Fields.CurrentPIN.Label')"
        type="password"
        :placeholder="$t('Account.PINReset.Fields.CurrentPIN.Placeholder')"
        can-show-password
        :model="currentPIN"
        :max="4"
        :error="error === 'InvalidPIN'"
        :is-numeric="true"
        :inputmode="'numeric'"
        :pattern="'[0-9]*'"
        @update:model="setCurrentPIN"
      />
      <div class="form-fields">
        <Input
          id="new-pin"
          :label="$t('Account.PINReset.Fields.NewPIN.Label')"
          type="password"
          :placeholder="$t('Account.PINReset.Fields.NewPIN.Placeholder')"
          :model="newPIN"
          :max="4"
          :error="error === 'RequiredSteps'"
          :is-numeric="true"
          :inputmode="'numeric'"
          :pattern="'[0-9]*'"
          @update:model="setNewPIN"
        />
        <Input
          id="confirm-new-pin"
          :label="$t('Account.PINReset.Fields.ConfirmNewPIN.Label')"
          type="password"
          :placeholder="$t('Account.PINReset.Fields.ConfirmNewPIN.Placeholder')"
          :model="confirmNewPIN"
          :max="4"
          :error="error === 'RequiredSteps'"
          :is-numeric="true"
          :inputmode="'numeric'"
          :pattern="'[0-9]*'"
          @update:model="setConfirmNewPIN"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Input from '@/views/account/components/Input.vue';
  import ErrorPopup from '@/views/account/components/ErrorPopup.vue';
  import ValidationItem from '@/views/account/components/ValidationItem.vue';
  import Button from '@/views/account/components/Button.vue';
  import { mapActions, mapGetters } from 'vuex';
  export default {
    components: {
      ValidationItem,
      Button,
      Input,
      ErrorPopup
    },
    computed: {
      ...mapGetters('accountStore', ['currentPIN', 'newPIN', 'confirmNewPIN']),
      hasFourNumbers() {
        return /^\d{4}$/.test(this.newPIN);
      },
      isNotSequence() {
        const sequences =
          /^(?!1234$|4321$|2345$|5432$|3456$|6543$|4567$|7654$|5678$|8765$|6789$|9876$|0123$|3210)\d{4}$/;
        return sequences.test(this.newPIN);
      },
      pinsAreEqual() {
        const { newPIN, confirmNewPIN } = this;
        return newPIN === confirmNewPIN && !!newPIN;
      }
    },
    methods: {
      ...mapActions('accountStore', ['setCurrentPIN', 'setNewPIN', 'setConfirmNewPIN']),
      openModal() {
        this.$emit('openModal');
      },
      setError(error) {
        this.error = error;
      },
      validate() {
        const { hasFourNumbers, isNotSequence, pinsAreEqual, newPIN, confirmNewPIN, currentPIN } =
          this;

        const isValid = hasFourNumbers && isNotSequence && pinsAreEqual;
        const filledSomething = [newPIN, confirmNewPIN, currentPIN].some((item) => item);

        if (!isValid && filledSomething) {
          this.error = 'RequiredSteps';
          return false;
        }
        this.error = '';

        if (!filledSomething) {
          return { valid: true, changed: false };
        }

        return { valid: true, changed: true };
      }
    },
    data: () => ({
      error: ''
    })
  };
</script>

<style lang="scss" scoped>
  h3,
  h4 {
    margin-bottom: 8px;
  }
  .form-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  #pin-reset-form {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 30px;
  }

  .title {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ul {
    margin-bottom: 24px;
  }

  .form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .forgot-pin-btn {
    max-width: 158px;
    max-height: 28px;
  }

  .error-popup {
    max-height: 58px;
  }

  @media (max-width: 1002px) {
    #pin-reset-form {
      flex-wrap: wrap;
      gap: 24px;
    }

    .title {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }

    h4 {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .form-fields {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
</style>
