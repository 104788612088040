var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tooltip',{attrs:{"target":_vm.target,"triggers":_vm.triggers,"placement":_vm.placement,"custom-class":{
    'tooltip-default': true,
    'place-top': _vm.placement == 'top',
    'place-right': _vm.placement == 'right',
    'place-bottom ': _vm.placement == 'bottom',
    'place-left': _vm.placement == 'left'
  }}},[_vm._v(" "+_vm._s(_vm.text)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }