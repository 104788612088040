<template>
  <LoginPageLayoutCard>
    <LoginCardHeader />
    <LoginForm />
  </LoginPageLayoutCard>
</template>

<script>
  import LoginPageLayoutCard from '@/views/authentication/components/card-layout';
  import LoginCardHeader from './components/header';
  import LoginForm from './components/form';

  export default {
    name: 'LoginCard',
    components: {
      LoginPageLayoutCard,
      LoginCardHeader,
      LoginForm
    }
  };
</script>

<style lang="scss" scoped></style>
