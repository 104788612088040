<template>
  <div
    v-if="variant === 'full'"
    :class="['nav-item-group-full', !border ? 'hide-border' : '']"
  >
    <header @click="open = !open">
      <span>{{ title }}</span>
      <div :class="['icon-full', open ? 'flipped' : '']">
        <ArrowRight class="icon-full" />
      </div>
    </header>
    <transition name="navgroup">
      <div
        v-show="open"
        class="items"
      >
        <slot />
      </div>
    </transition>
  </div>
  <div
    v-else
    class="nav-item-group-short"
  >
    <slot />
  </div>
</template>

<script>
  import ArrowRight from '@/assets/icons/arrow-right.svg';
  export default {
    components: { ArrowRight },
    props: {
      variant: {
        type: String,
        default: 'full'
      },
      title: {
        required: true
      },
      border: {
        type: Boolean,
        default: true
      }
    },
    data: () => ({
      open: true
    }),
    beforeDestroy() {
      this.open = false;
    }
  };
</script>

<style lang="scss">
  .navgroup-enter-active,
  .navgroup-leave-active {
    transition: max-height 0.35s, opacity 0.18s;

    max-height: 500px !important;
  }
  .navgroup-enter,
  .navgroup-leave-to {
    opacity: 0;
    max-height: 0px !important;
  }
</style>

<style lang="scss" scoped>
  .hide-border {
    border-bottom: 1px solid transparent !important;
  }
  .icon-full {
    width: 16px;
    height: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: auto 0;

    &.flipped {
      transform: rotate(90deg);
      transform-origin: center;
    }
  }

  .nav-item-group-full {
    border-bottom: 1px solid #4c4541;
    padding-bottom: 12px;
    header {
      padding: 8px 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;

      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
        user-select: none;
      }
    }
    .items {
      padding: 12px 0 0 0;
      gap: 4px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      max-height: 600px;
    }
  }

  .nav-item-group-short {
    display: flex;
    padding: 16px 0 0 0;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-top: 1px solid #4c4541;
  }
</style>
