export default {
  namespaced: true,
  state: {
    ruleStatus1: false,
    ruleStatus2: false,
    ruleStatus3: false,
    flagShowResetCard: false,
    flagShowRestoredPinCard: false,
    flagShowExpiredLinkResetPinCard: false,
    flagShowResentLinkResetPinCard: false
  },
  getters: {
    ruleStatus1: (state) => state.ruleStatus1,
    ruleStatus2: (state) => state.ruleStatus2,
    ruleStatus3: (state) => state.ruleStatus3,
    flagShowResetCard: (state) => state.flagShowResetCard,
    flagShowRestoredPinCard: (state) => state.flagShowRestoredPinCard,
    flagShowExpiredLinkResetPinCard: (state) => state.flagShowExpiredLinkResetPinCard,
    flagShowResentLinkResetPinCard: (state) => state.flagShowResentLinkResetPinCard
  },
  mutations: {
    UPDATE_RULE_STATUS_1: (state, payload) => (state.ruleStatus1 = payload),
    UPDATE_RULE_STATUS_2: (state, payload) => (state.ruleStatus2 = payload),
    UPDATE_RULE_STATUS_3: (state, payload) => (state.ruleStatus3 = payload),
    UPDATE_FLAG_SHOW_RESET_CARD: (state, payload) => (state.flagShowResetCard = payload),
    UPDATE_FLAG_SHOW_RESTORED_PIN_CARD: (state, payload) =>
      (state.flagShowRestoredPinCard = payload),
    UPDATE_FLAG_SHOW_EXPIRED_LINK_RESET_PIN_CARD: (state, payload) =>
      (state.flagShowExpiredLinkResetPinCard = payload),
    UPDATE_FLAG_SHOW_RESENT_LINK_RESET_PIN_CARD: (state, payload) =>
      (state.flagShowResentLinkResetPinCard = payload)
  },
  actions: {
    showResetCard({ commit }) {
      commit('UPDATE_FLAG_SHOW_RESET_CARD', true);
    },
    showRestoredPinCard({ commit }) {
      commit('UPDATE_FLAG_SHOW_RESET_CARD', false);
      commit('UPDATE_FLAG_SHOW_RESTORED_PIN_CARD', true);
    },
    showExpiredLinkResetPinCard({ commit }) {
      commit('UPDATE_FLAG_SHOW_RESET_CARD', false);
      commit('UPDATE_FLAG_SHOW_EXPIRED_LINK_RESET_PIN_CARD', true);
    },
    showResentLinkResetPinCard({ commit }) {
      commit('UPDATE_FLAG_SHOW_EXPIRED_LINK_RESET_PIN_CARD', false);
      commit('UPDATE_FLAG_SHOW_RESENT_LINK_RESET_PIN_CARD', true);
    },
    clearResetPinStore({ commit }) {
      commit('UPDATE_RULE_STATUS_1', false);
      commit('UPDATE_RULE_STATUS_2', false);
      commit('UPDATE_RULE_STATUS_3', false);
      commit('UPDATE_FLAG_SHOW_RESET_CARD', false);
      commit('UPDATE_FLAG_SHOW_RESTORED_PIN_CARD', false);
      commit('UPDATE_FLAG_SHOW_RESENT_LINK_RESET_PIN_CARD', false);
      commit('UPDATE_FLAG_SHOW_EXPIRED_LINK_RESET_PIN_CARD', false);
    }
  }
};
