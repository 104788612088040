<template>
  <div>
    <ModalTemplateForgot
      :modal-title="$t('AccountPage.Modal.PinModal.Title')"
      :modal-description="$t('AccountPage.Modal.PinModal.Description')"
      :email="email"
      @cancel="cancel"
      @send="send"
    />
  </div>
</template>

<script>
  import ModalTemplateForgot from '@/views/account/components/modal-template';

  export default {
    name: 'ForgotPinModal',
    components: {
      ModalTemplateForgot
    },
    data: () => ({
      email: ''
    }),
    created() {
      this.getEmail();
    },
    methods: {
      async send() {
        const splitedEmail = this.email.split('@');
        const prefix = splitedEmail[1].split('.')[0];

        this._showLoading();

        const { ip } = await fetch('https://api.ipify.org/?format=json')
          .then((res) => res.json())
          .catch(() => ({ ip: '' }));

        const payload = {
          email: this.email,
          ip
        };

        this.$http
          .post(`/api/${prefix}/pin/reset`, payload)
          .then(() => {
            this.$emit('close');
            this._showToast(this.$t('Success'), '', this.$t('AccountPage.SentEmail'), 'success');
          })
          .catch(() => {})
          .finally(() => {
            this._hideLoading();
          });
      },
      cancel() {
        this.$emit('close');
      },
      getEmail() {
        const userData = localStorage.getItem('userData');
        const { email } = JSON.parse(userData);
        this.email = email;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
