<template>
  <ul id="rsp__status-items">
    <StatusItem
      rule-description="Mínimo 6 caracteres"
      :rule-status="_ruleStatus1"
    />
    <StatusItem
      rule-description="Deve conter ao menos 1 número"
      :rule-status="_ruleStatus2"
    />
    <StatusItem
      rule-description="Deve conter ao menos 1 caractere especial"
      :rule-status="_ruleStatus3"
    />
    <StatusItem
      rule-description="As senhas devem ser iguais"
      :rule-status="_ruleStatus4"
    />
  </ul>
</template>

<script>
  import resetPasswordMixin from '@/mixins/reset-password';
  import StatusItem from './components/status-item';

  export default {
    name: 'PasswordStatus',
    mixins: [resetPasswordMixin],
    components: {
      StatusItem
    }
  };
</script>

<style lang="scss" scoped>
  #rsp__status-items {
    margin: 8px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 4px 0;
  }

  @media (max-width: 485px) {
    #rsp__status-items {
      gap: 0 !important;
    }
  }
</style>
