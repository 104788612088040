<template>
  <main id="rsp__page-c">
    <ResetCard v-if="_flagShowResetCard" />

    <NotificationCard
      v-if="_flagShowRestoredPasswordCard"
      :card-icon="notification.retoredPassword.icon"
      :card-title="notification.retoredPassword.title"
      :card-subtitle="notification.retoredPassword.subtitle"
      :button-text="notification.retoredPassword.buttonText"
      :button-event="redirect"
    />

    <NotificationCard
      v-if="_flagShowExpiredLinkResetPasswordCard"
      :card-icon="notification.expiredLink.icon"
      :card-title="notification.expiredLink.title"
      :card-subtitle="notification.expiredLink.subtitle"
      :button-text="notification.expiredLink.buttonText"
      :button-event="resendEmail"
    />

    <ResentLinkCard
      v-if="_flagShowResentLinkResetPasswordCard"
      :card-icon="notification.reSentLink.icon"
      :card-title="notification.reSentLink.title"
      :card-subtitle="notification.reSentLink.subtitle"
      :email="email"
    />
  </main>
</template>

<script>
  import ResetCard from './components/reset-card';
  import NotificationCard from '@/views/authentication/components/notification-card';
  import ResentLinkCard from '@/views/authentication/components/resent-link-card';

  import resetPasswordMixin from '@/mixins/reset-password';

  export default {
    name: 'ResetPassword',
    components: {
      ResetCard,
      NotificationCard,
      ResentLinkCard
    },
    mixins: [resetPasswordMixin],
    data: () => ({
      notification: {
        retoredPassword: {
          icon: 'check',
          title: 'Senha restaurada',
          subtitle: 'Agora você pode acessar sua conta com a nova senha criada',
          buttonText: 'Realizar Login'
        },
        expiredLink: {
          icon: 'alert',
          title: 'O Link Expirou',
          subtitle: 'Gere um novo link para restaurar sua senha',
          buttonText: 'Gerar Novo Link'
        },
        reSentLink: {
          icon: 'check',
          title: 'Link enviado',
          subtitle: 'Enviamos um novo link para o e-mail'
        }
      },
      email: '',
      eventListenerKeydown: null
    }),
    mounted() {
      this.checkToken();
    },
    destroyed() {
      this._clearResetPasswordStore();
    },
    methods: {
      redirect() {
        const hasUser = localStorage.getItem('userData');

        hasUser ? this.redirectToHomePage() : this.redirectToLoginPage();
      },
      redirectToLoginPage() {
        this.$router.push({ name: 'login' }).catch(() => {});
      },
      redirectToHomePage() {
        this.$router.push({ name: 'home', params: { prefix: this._prefix } }).catch(() => {});
      },
      async resendEmail() {
        this._showLoading();

        const { ip } = await fetch('https://api.ipify.org/?format=json')
          .then((res) => res.json())
          .catch(() => ({ ip: '' }));

        const path = `/api/${this._prefix}/reset`;
        const payload = {
          email: this.email,
          ip
        };

        this.$http
          .post(path, payload)
          .then(() => {
            this._showResentLinkResetPasswordCard();
          })
          .catch(() => {})
          .finally(() => {
            this._hideLoading();
          });
      },
      checkToken() {
        const token = this.$router.currentRoute.params.token ?? '';

        const path = `/api/${this._prefix}/check-link`;
        const payload = {
          code: token
        };

        this._showLoading();
        this.$http
          .post(path, payload)
          .then((response) => {
            const {
              data: { email }
            } = response;
            this.email = email;
            this._showResetCard();
          })
          .catch((error) => {
            const {
              response: {
                data: { email },
                status
              }
            } = error;

            if (status === 403 && !!email) {
              this.email = email;
              this._showExpiredLinkResetPasswordCard();
              return;
            }

            if (status === 403 && !email && !localStorage.getItem('userData')) {
              this.redirectToLoginPage();
              return;
            }

            if (status === 403 && !email && localStorage.getItem('userData')) {
              this.redirectToHomePage();
            }
          })
          .finally(() => {
            this._hideLoading();
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  #rsp__page-c {
    min-height: 100dvh;
    width: 100%;
    padding: 37px 18px;
    background-image: url('../../../assets/images/cubos.png');
    background-color: #362f2b;
    background-size: 175px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
