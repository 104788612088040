<template>
  <Sidebar
    v-if="desktop"
    :device="device"
  />
  <Navbar v-else />
</template>

<script>
  import Navbar from '@/components/Menu/Navbar/index.vue';
  import Sidebar from '@/components/Menu/Sidebar/index.vue';

  import { mapGetters } from 'vuex';

  export default {
    components: { Navbar, Sidebar },
    props: {
      desktop: {
        type: Boolean,
        required: true
      },
      device: {
        type: String,
        required: true,
        default: ''
      }
    },
    computed: {
      ...mapGetters('userStore', ['user'])
    }
  };
</script>
