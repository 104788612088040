<template>
  <button
    :class="customClass"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
  export default {
    props: ['customClass']
  };
</script>

<style lang="scss" scoped>
  button {
    padding: 4px 14px;
    border-radius: 5px;
    background: #974900;
    outline: 0;
    border: 0;

    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.0125em;
    text-align: center;
    color: #fff;

    &:hover {
      background: #9f5714;
    }

    &:active {
      background: #a45f1f;
    }
  }
</style>
