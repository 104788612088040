<template>
  <LoginPageLayoutCard>
    <ResetPasswordCardHeader />
    <ResetPasswordCardForm />
  </LoginPageLayoutCard>
</template>

<script>
  import LoginPageLayoutCard from '@/views/authentication/components/card-layout';
  import ResetPasswordCardHeader from './components/header';
  import ResetPasswordCardForm from './components/form';

  export default {
    name: 'ResetCard',
    components: {
      LoginPageLayoutCard,
      ResetPasswordCardHeader,
      ResetPasswordCardForm
    }
  };
</script>

<style lang="scss" scoped></style>
