<template>
  <h6>
    {{ $t('Footer.Copyright') }}
    {{ new Date().getFullYear() }}
    <span id="indsiders">{{ $t('Footer.Indsiders') }}</span
    >.
    <span v-show="showAllRightsReserved">{{ $t('Footer.AllRightsReserved') }}</span>
  </h6>
</template>

<script>
  export default {
    data: () => ({
      showAllRightsReserved: window.innerWidth > 540
    }),
    beforeMount() {
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.showAllRightsReserved = window.innerWidth > 540;
      }
    }
  };
</script>

<style lang="scss" scoped>
  h6 {
    padding: 24px 29px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  span,
  h6 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    font-family: Raleway;

    letter-spacing: 0em;
    text-align: left;
  }
  #indsiders {
    color: #ff8d2e;
  }

  @media (max-width: 480px) {
    h6 {
      padding: 24px 16px;
    }
  }
</style>
