<template>
  <div
    :class="['profile-full', open ? 'open' : '']"
    @click="openProfileOptions"
  >
    <div class="profile">
      <div class="user-data">
        <img
          v-if="user.picture"
          :src="user.picture"
          alt="Profile Image"
        />
        <Placeholder
          v-else
          size="38px"
          :text="user.abbreviation"
        />
        <div class="user">
          <strong>{{ user.name }}</strong>
          <span>{{ user.role }}</span>
        </div>
      </div>
      <button class="close-btn">
        <ArrowDown :class="['arrow-down-icon', open ? 'flipped' : '']" />
      </button>
    </div>
    <transition name="card">
      <div
        v-if="open"
        class="profile-options"
      >
        <button
          v-for="item in items"
          :key="item.id"
          class="profile-options-btn"
          @click="item.click"
        >
          <div class="icon">
            <component
              :is="item.icon"
              class="button-icon"
            />
          </div>
          <span>{{ $t(item.title) }}</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
  import Placeholder from '@/components/Menu/AvatarPlaceholder.vue';

  export default {
    name: 'ProfileCard',
    components: {
      ArrowDown: () => import('@/assets/icons/arrow-down.svg'),
      ExitIcon: () => import('@/assets/icons/exit-icon.svg'),
      ProfileIcon: () => import('@/assets/icons/profile-icon.svg'),
      Placeholder
    },
    props: {
      user: {
        type: Object,
        required: true,
        default: () => ({
          name: 'Rodrigo Faro',
          role: 'Desenvolvedor front-end',
          picture: ''
        })
      },
      items: {
        type: Array,
        required: true,
        default: () => [
          {
            id: 1,
            title: 'Minha Conta',
            icon: 'icon',
            click: () => {}
          }
        ]
      }
    },
    data: () => ({
      open: false
    }),
    methods: {
      openProfileOptions() {
        this.open = !this.open;
      },
      handleRedirectProfile(e) {
        e.preventDefault();
        e.stopPropagation();
      },
      handleSignOut(e) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };
</script>

<style lang="scss">
  .card-enter-active,
  .card-leave-active {
    transition: all 0.35s, opacity 0.18s;
    max-height: 500px !important;
  }
  .card-enter,
  .card-leave-to {
    opacity: 0;
    max-height: 0px !important;
  }
</style>

<style lang="scss" scoped>
  .avatar-name-initials {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background-color: #6c757d;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    span {
      color: white;
    }
  }
  .close-btn {
    padding: 0;
  }
  .user-data {
    display: flex;
    flex-direction: row;
    gap: 14px;
    width: calc(100% - 30px);
  }
  .arrow-down-icon {
    width: 16px;
    height: 16px;
    justify-self: flex-end;
    margin: 0;

    &.flipped {
      transform: rotate(180deg);
    }
  }

  .button-icon {
    width: 14px;
    height: 14px;

    margin-top: 2px;
  }

  .profile-full {
    &:not(.open) {
      height: 70px !important;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;

    min-width: 216.5px;
    min-height: 70px;

    border-radius: 6px;
    border: 1px solid #4c4541;
    user-select: none;
    cursor: pointer;

    padding-bottom: 16px;

    .profile {
      display: flex;
      flex-direction: row;
      gap: 14px;
      padding: 16px 16px 0 16px;
      justify-content: space-between;
      width: 100%;
    }

    .profile-options {
      display: flex;
      flex-direction: column;
      max-height: 150px;
    }

    &:hover {
      border: 1px solid #974900;
    }

    &:active:not(:focus-within) {
      border: 1px solid #e1730b;
      background: rgba(151, 73, 0, 0.16);
    }

    img {
      width: 38px;
      height: 38px;
      border-radius: 100%;
      object-fit: cover;
    }

    .user {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      width: calc(100% - 52px);

      strong {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 95px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 95px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #cfc4be;
      }
    }

    button {
      cursor: pointer;
      outline: 0;
      background-color: transparent;
      border: 0;
    }
  }

  .profile-options-btn {
    width: 100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;

    border-top: 1px solid transparent !important;
    border-bottom: 1px solid transparent !important;

    &:hover {
      border-top: 1px solid #974900 !important;
      border-bottom: 1px solid #974900 !important;
      background: rgba(151, 73, 0, 0.05);

      span {
        color: #fff;
      }
      svg,
      path {
        fill: #fff;
      }
    }

    &:active {
      border-top: 1px solid #974900 !important;
      border-bottom: 1px solid #974900 !important;
      background: rgba(151, 73, 0, 0.16);

      span {
        color: #fff;
      }
      svg,
      path {
        fill: #fff;
      }
    }

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #cfc4be;
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
