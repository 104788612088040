<template>
  <div class="tmf__modal-w">
    <div class="tmf__modal-c">
      <ModalTemplateForgotTitle :modalTitle="modalTitle" />
      <ModalTemplateForgotDescription
        :modal-description="modalDescription"
        :email="email"
      />
      <div class="tmf__buttons-c">
        <ModalTemplateForgotButton
          :button-text="$t('AccountPage.Modal.Cancel')"
          button-type="cancel"
          @click="cancel"
        />
        <ModalTemplateForgotButton
          :button-text="$t('AccountPage.Modal.Send')"
          button-type="send"
          @click="send"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ModalTemplateForgotTitle from './components/title';
  import ModalTemplateForgotDescription from './components/description';
  import ModalTemplateForgotButton from './components/button';

  export default {
    name: 'ModalTemplateForgot',
    components: {
      ModalTemplateForgotTitle,
      ModalTemplateForgotDescription,
      ModalTemplateForgotButton
    },
    props: {
      modalTitle: {
        type: String,
        default: ''
      },
      modalDescription: {
        type: String,
        default: ''
      },
      email: {
        type: String,
        default: ''
      }
    },
    methods: {
      send() {
        this.$emit('send');
      },
      cancel() {
        this.$emit('cancel');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .tmf__modal-w {
    background: rgba(54, 47, 43, 0.2);
    backdrop-filter: blur(25px);
    padding: 16px;
    z-index: 10;

    height: 100dvh;
    width: 100%;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    animation: opacityanimation 0.2s ease;

    @keyframes opacityanimation {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .tmf__modal-c {
      width: 100%;
      max-width: 562px;

      display: flex;
      flex-direction: column;

      .tmf__buttons-c {
        display: flex;
        gap: 16px;
      }
    }
  }
</style>

<style lang="scss">
  body:has(.tmf__modal-w) {
    overflow: hidden !important;
  }
</style>
