<template>
  <div id="profile">
    <div v-if="variant === 'short'">
      <Picture
        id="user-picture"
        :url="user.picture"
        :name="user.name"
        :abbreviation="user.abbreviation"
      />
      <Tooltip
        target="user-picture"
        :text="$t('Menu.Titles.Account')"
      />
      <Popover
        :items="items"
        target="user-picture"
        :title="title"
        placement="rightbottom"
      />
    </div>

    <div
      v-else
      class="user-info__card"
    >
      <Card
        :user="user"
        :items="items"
      />
    </div>
  </div>
</template>

<script>
  // Components
  import Picture from '@/components/Menu/Profile/Picture.vue';
  import Card from '@/components/Menu/Profile/Card.vue';
  import Popover from '@/components/Menu/Popover.vue';
  import Tooltip from '@/components/Menu/Tooltip.vue';

  // Icons
  import ProfileIcon from '@/assets/icons/profile-icon.svg';
  import ExitIcon from '@/assets/icons/exit-icon.svg';

  const ICONS = {
    ProfileIcon,
    ExitIcon
  };

  export default {
    name: 'ProfileComponent',
    components: {
      Picture,
      Card,
      Popover,
      Tooltip
    },
    props: {
      variant: {
        type: String,
        default: 'short'
      },
      user: {
        type: Object,
        required: true,
        default: () => ({
          name: 'Rodrigo Faro',
          role: 'Desenvolvedor front-end',
          picture: ''
        })
      },
      title: {
        type: String,
        default: '  ',
        required: true
      }
    },
    data() {
      return {
        open: false,
        ICONS
      };
    },
    computed: {
      items() {
        return [
          {
            title: 'Menu.Items.Account',
            icon: ICONS.ProfileIcon,
            click: () => this.redirect('account'),
            selected: this.$route.name === 'account'
          },
          {
            title: 'Menu.Items.Exit',
            icon: ICONS.ExitIcon,
            click: () => this._logout()
          }
        ];
      }
    },
    methods: {
      redirect(page) {
        this.$emit('close');

        if (this.$route.name === page) return;

        this.$router.push({ name: page }).catch(() => {});
      }
    }
  };
</script>

<style lang="scss" scoped>
  #profile:has(.user-info__card) {
    width: inherit;
  }
</style>
