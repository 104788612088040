import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('loginStore', {})
  },
  methods: {
    ...mapActions('loginStore', {})
  }
};
