export default {
  namespaced: true,
  state: {
    flagShowLoginCard: true,
    flagShowForgotCard: false,
    flagShowSendedLinkCardWithButton: false
  },
  getters: {
    flagShowLoginCard: (state) => state.flagShowLoginCard,
    flagShowForgotCard: (state) => state.flagShowForgotCard,
    flagShowSendedLinkCardWithButton: (state) => state.flagShowSendedLinkCardWithButton
  },
  mutations: {
    UPDATE_FLAG_SHOW_LOGIN_CARD(state, payload) {
      state.flagShowLoginCard = payload;
    },
    UPDATE_FLAG_SHOW_FORGOT_CARD(state, payload) {
      state.flagShowForgotCard = payload;
    },
    UPDATE_FLAG_SHOW_SENDED_LINK_CARD_WITH_BUTTON(state, payload) {
      state.flagShowSendedLinkCardWithButton = payload;
    }
  },
  actions: {
    showLoginCard({ commit }) {
      commit('UPDATE_FLAG_SHOW_LOGIN_CARD', true);
      commit('UPDATE_FLAG_SHOW_FORGOT_CARD', false);
      commit('UPDATE_FLAG_SHOW_SENDED_LINK_CARD_WITH_BUTTON', false);
    },
    showForgotCard({ commit }) {
      commit('UPDATE_FLAG_SHOW_LOGIN_CARD', false);
      commit('UPDATE_FLAG_SHOW_FORGOT_CARD', true);
      commit('UPDATE_FLAG_SHOW_SENDED_LINK_CARD_WITH_BUTTON', false);
    },
    showSendedLinkCardWithButton({ commit }) {
      commit('UPDATE_FLAG_SHOW_LOGIN_CARD', false);
      commit('UPDATE_FLAG_SHOW_FORGOT_CARD', false);
      commit('UPDATE_FLAG_SHOW_SENDED_LINK_CARD_WITH_BUTTON', true);
    }
  }
};
