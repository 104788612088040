<template>
  <div class="rlc__email-link-c rlc__ellipsis">
    {{ email }}
  </div>
</template>

<script>
  export default {
    name: 'ResentLinkCardContent',
    props: {
      email: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss" scoped>
  .rlc__ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  .rlc__email-link-c {
    margin: 0 auto;
    color: #4c4541;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
  }

  @media (max-width: 485px) {
    .rlc__email-link-c {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
</style>
