<template>
  <a
    class="card-product"
    :href="data.redirect"
  >
    <div class="logo">
      <MySfc v-if="data.name == 'MySFC'" />
      <MyConnect v-if="data.name == 'MyConnect'" />
      <MyDashboard v-if="data.name == 'MyDashboard'" />
      <MyConfig v-if="data.name == 'MyConfig'" />
    </div>

    <div class="info">
      <div class="divider"></div>

      <span>
        {{ $t(`${data.info}`) }}
      </span>
    </div>
  </a>
</template>

<script>
  export default {
    name: 'CardProduct',
    components: {
      MySfc: () => import('@/assets/icons/mysfc.svg'),
      MyConnect: () => import('@/assets/icons/myconnect.svg'),
      MyDashboard: () => import('@/assets/icons/mydashboard.svg'),
      MyConfig: () => import('@/assets/icons/myconfig.svg')
    },
    props: {
      data: {
        type: Object,
        default: () => ({})
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card-product {
    width: calc(50% - 15px);
    max-width: 514px;
    display: flex;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 24px;
    text-decoration: none;
    border: 1px solid #4c4541;
    background: rgba(26, 28, 25, 0.2);
    backdrop-filter: blur(25px);
    transition: background-color 0.5s;
    height: 189.5px;

    cursor: pointer;

    &:hover {
      border: 1px solid #974900;
      background: rgba(151, 73, 0, 0.05);
      backdrop-filter: blur(25px);
    }

    .logo {
      width: 100%;
    }

    .info {
      display: flex;
      width: 100%;
      text-align: left;

      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: #fff;
      }

      gap: 8px;

      .divider {
        align-self: stretch;
        border-left: 1px solid #ffffff !important;
      }
    }
  }

  @media screen and (max-width: 1215px) {
    .card-product {
      flex-direction: row;
    }
  }

  @media screen and (max-width: 1000px) {
    .card-product {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 768px) {
    .card-product {
      height: 326px;
      width: calc(50% - 8px);
    }
  }

  @media screen and (max-width: 575px) {
    .card-product {
      height: 200px;
      width: 100%;
      flex-direction: row;
    }
  }

  @media screen and (max-width: 425px) {
    .card-product {
      height: 100px;
      padding: 0;

      .info {
        display: none;
      }
    }
  }
</style>
