<template>
  <div
    v-if="!valid"
    class="validation-item"
  >
    <div class="bullet-icon-container">
      <BulletIcon class="bullet-icon" />
    </div>
    <span>{{ text }}</span>
  </div>
  <div
    v-else
    class="validation-item valid"
  >
    <CheckIcon class="check-icon" />
    <span>{{ text }}</span>
  </div>
</template>

<script>
  import CheckIcon from '@/assets/icons/check-status.svg';
  import BulletIcon from '@/assets/icons/list-item.svg';
  export default {
    name: 'ValidationItem',
    components: { CheckIcon, BulletIcon },
    props: {
      valid: {
        type: Boolean,
        default: false
      },
      text: {
        type: String,
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #fff;
    text-align: left;
  }
  .validation-item {
    display: flex;
    flex-direction: row;
    gap: 8px;

    &.valid {
      span {
        color: #66bb6a;
      }
    }
  }
  .check-icon {
    width: 12px;
    height: 12px;

    align-self: center;
    margin-bottom: 2px;
  }
  .bullet-icon {
    width: 4px;
    height: 4px;

    align-self: center;
  }
  .bullet-icon-container {
    width: 12px;
    height: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  @media (max-width: 480px) {
    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .validation-item:not(.valid) {
      span {
        margin-left: -4px;
      }
    }

    .check-icon {
      width: 8px;
      height: 8px;

      margin-top: 2px;
    }

    .bullet-icon {
      margin-left: -4px;
    }
  }
</style>
