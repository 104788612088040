var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'tmf__button',
    {
      'tmf__cancel-button': _vm.buttonType === 'cancel',
      'tmf__send-button': _vm.buttonType === 'send'
    }
  ],attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }