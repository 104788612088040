<template>
  <div id="save-changes-modal">
    <span
      ><strong> {{ $t('Account.SaveChanges.Attention') }} | </strong>
      {{ $t('Account.SaveChanges.UnsavedChanges') }}
    </span>
    <div class="actions">
      <button
        class="undo-button"
        @click="$emit('undo')"
      >
        {{ $t('Account.SaveChanges.Undo') }}
      </button>
      <button
        class="save-button"
        @click="$emit('save')"
      >
        {{ $t('Account.SaveChanges.Save') }}
      </button>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  #save-changes-modal {
    z-index: 2;
    padding: 16px;

    border-radius: 6px;
    border: 1px solid #4c4541;
    background: rgba(26, 28, 25, 0.2);
    backdrop-filter: blur(25px);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 16px;

    max-width: 563px;

    // Posicionamento em tela
    width: -webkit-fill-available;
    height: 70px;
    position: fixed;
    bottom: 24px;
  }

  strong,
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #cfc4be;
  }

  strong {
    font-weight: 600;
    color: #fff;
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  button {
    padding: 6px 21px;
    background: transparent;
    outline: 0;
    border: none;
    border-radius: 5px;

    line-height: 20px;
    font-weight: 600;
    font-size: 14px;
  }

  .undo-button {
    border: 1px solid #ef5350;
    color: #ef5350;

    &:hover {
      border-color: #ffcdd2;
      color: #ffcdd2;
    }
    &:active {
      border-color: #e53935;
      color: #e53935;
    }
  }

  .save-button {
    background: #974900;
    color: #fff;

    &:hover {
      background: #9f5714;
    }

    &:active {
      background: #a45f1f;
    }
  }

  @media (max-width: 768px) {
    #save-changes-modal {
      max-width: calc(100% - 56px);
    }
  }

  @media (max-width: 480px) {
    #save-changes-modal {
      flex-direction: column;
      height: unset;
      max-width: calc(100% - 30px);
    }

    button,
    span,
    strong {
      font-size: 12px;
    }

    span,
    strong {
      line-height: 16px;
    }

    button {
      line-height: 20px;
      flex: 1;
    }

    .actions {
      justify-content: center;
      width: 100%;
    }
  }
</style>
