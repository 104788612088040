<template>
  <button
    :id="id"
    :class="['profile-short', !url ? 'placeholder' : '']"
    tabindex="0"
  >
    <img
      v-if="url"
      :src="url"
      alt="Profile Image"
    />
    <Placeholder
      v-else
      size="44px"
      :text="abbreviation"
    />
  </button>
</template>

<script>
  import Placeholder from '@/components/Menu/AvatarPlaceholder.vue';

  export default {
    name: 'ProfilePicture',
    components: { Placeholder },
    props: {
      id: {
        type: String
      },
      url: {
        type: String,
        default: null
      },
      abbreviation: {
        type: String,
        default: 'LN'
      }
    }
  };
</script>

<style lang="scss" scoped>
  .profile-short {
    outline: 0;
    background-color: transparent;
    border: 0;

    width: auto;
    height: 56px;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    img {
      width: 44px;
      height: 44px;
      border-radius: 100%;
      object-fit: cover;
    }

    border: 1px solid transparent;

    &:hover {
      border: 1px solid #974900;
    }

    &:active {
      border: 1px solid #e1730b;
    }
  }

  span {
    border-radius: 50%;
    width: 44px !important;
    height: 44px;
  }
</style>
