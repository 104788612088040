<template>
  <div id="nav-site-selector__wrapper">
    <div
      id="nav-site-selector__container"
      tabindex="0"
    >
      <span>{{ currentSite.name }}</span>
      <ArrowDown class="arrow-down" />
    </div>
    <Popover
      :title="$t('Menu.Items.Sites')"
      placement="down"
      target="nav-site-selector__container"
      :items="items"
    />
  </div>
</template>

<script>
  import Popover from '@/components/Menu/Popover.vue';
  import ArrowDown from '@/assets/icons/arrow-down.svg';

  export default {
    components: { ArrowDown, Popover },
    computed: {
      currentSite: () => JSON.parse(localStorage.getItem('currentSite')),
      items() {
        const sites = JSON.parse(localStorage.getItem('sites'));
        const { currentSite, changeSite } = this;
        return sites.map((site) => ({
          selected: currentSite.guid === site.guid,
          click: () => changeSite(site),
          title: site.name
        }));
      }
    },
    methods: {
      changeSite(site) {
        if (site.guid === this.currentSite.guid) return;

        localStorage.setItem('currentSite', JSON.stringify(site));
        const userInfo = this.$cookies.get("userInfo");
        const domain = window.location.href.includes('indsiders.com');
        this.$cookies.set(
            "userInfo",
            {...userInfo, currentSite: site },
            "7D",
            null,
            domain ? "indsiders.com" : null
        );
        this.$router.go();
      }
    }
  };
</script>

<style lang="scss" scoped>
  #nav-site-selector__wrapper {
    min-width: 0;
  }

  #nav-site-selector__container {
    cursor: pointer;
    user-select: none;

    display: flex;
    flex: 1;
    gap: 8px;
    min-width: 0;
    margin-left: 20px;
    border-left: 1px solid #4c4541;
    padding: 20px 12px;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #cfc4be;

      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      background: #97490005;
      border-color: #974900;
    }

    &:active {
      background: #97490016;
    }
  }
  .arrow-down {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    margin-top: 3px;
    path {
      fill: #cfc4be;
    }
  }

  @media (max-width: 479px) or (min-width: 769px) {
    #nav-site-selector__wrapper {
      display: none;
    }
  }
</style>
