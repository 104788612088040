var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.handleResize),expression:"handleResize"}],staticClass:"support-page"},[_c('div',{staticClass:"custom-container"},[_c('header',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('SupportPage.Support'))+" ")]),_c('div',{staticClass:"divider"})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"contact"},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('SupportPage.NeedHelp'))+" ")]),_c('span',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(_vm.$t('SupportPage.ContactUs'))+" ")]),_c('div',{staticClass:"iboxes"},[_c('div',{staticClass:"ibox-email"},[_c('email-icon',{staticClass:"icons"}),_c('div',{staticClass:"info-ibox"},[_c('span',{staticClass:"title-ibox"},[_vm._v(" "+_vm._s(_vm.$t('SupportPage.Email'))+" ")]),_c('span',{staticClass:"subtitle-ibox"},[_vm._v(" "+_vm._s(_vm.$t('SupportPage.OurTeam'))+" ")]),_c('div',{staticClass:"line-ibox"},[_c('span',{staticClass:"email-whatsapp"},[_vm._v(_vm._s(_vm.email))]),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"btn-actions",attrs:{"id":"btn-open-email"},on:{"click":_vm.openEmail}},[_c('open-icon')],1),_c('div',{staticClass:"btn-actions",attrs:{"id":"btn-copy-email"},on:{"click":() => {
                        _vm.type = 'email';
                        _vm.copyToClipboard();
                      }}},[(!_vm.copied || (_vm.type && _vm.type !== 'email'))?_c('copy-icon'):(_vm.type === 'email')?_c('copied'):_vm._e()],1)])])]),(_vm.windowWidth > 768)?_c('tooltip',{attrs:{"text":_vm.copied && _vm.type === 'email'
                  ? _vm.$t('SupportPage.Copied')
                  : _vm.$t('SupportPage.CopyEmail'),"target":'btn-copy-email',"placement":'top'}}):_vm._e(),(_vm.windowWidth > 768)?_c('tooltip',{attrs:{"text":_vm.$t('SupportPage.OpenEmail'),"target":'btn-open-email',"placement":'top'}}):_vm._e()],1),_c('div',{staticClass:"divider-iboxes"}),_c('div',{staticClass:"ibox-whatsapp"},[_c('whats-icon',{staticClass:"icons"}),_c('div',{staticClass:"info-ibox"},[_c('span',{staticClass:"title-ibox"},[_vm._v(" WhatsApp ")]),_c('span',{staticClass:"subtitle-ibox"},[_vm._v(" "+_vm._s(_vm.$t('SupportPage.MontoFri'))+" ")]),_c('div',{staticClass:"line-ibox"},[_c('span',{staticClass:"email-whatsapp"},[_vm._v(_vm._s(_vm.phone))]),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"btn-actions",attrs:{"id":"btn-open-whats"},on:{"click":_vm.openWhatsapp}},[_c('open-icon')],1),_c('div',{staticClass:"btn-actions",attrs:{"id":"btn-copy-phone"},on:{"click":() => {
                        _vm.type = 'phone';
                        _vm.copyToClipboard();
                      }}},[(!_vm.copied || (_vm.type && _vm.type !== 'phone'))?_c('copy-icon'):(_vm.type === 'phone')?_c('copied'):_vm._e()],1)])])]),(_vm.windowWidth > 768)?_c('tooltip',{attrs:{"text":_vm.copied && _vm.type === 'phone'
                  ? _vm.$t('SupportPage.Copied')
                  : _vm.$t('SupportPage.CopyWhatsapp'),"target":'btn-copy-phone',"placement":'top'}}):_vm._e(),(_vm.windowWidth > 768)?_c('tooltip',{attrs:{"text":_vm.$t('SupportPage.OpenWhatsApp'),"target":'btn-open-whats',"placement":'top'}}):_vm._e()],1)])]),_c('div',{staticClass:"image"},[_c('div',{staticClass:"support-image",class:{ 'image-small': _vm.containerSize < 1059 }})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }