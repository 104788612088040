<template>
  <div class="upload-component">
    <input
      id="upload-button"
      type="file"
      hidden
      :accept="accept"
      @change="checkFile"
    />
    <label
      for="upload-button"
      class="upload"
    >
      <CloudIcon class="cloud-icon" />
      <span>{{ $t('Account.Photo.Upload') }}</span>
    </label>
  </div>
</template>

<script>
  import CloudIcon from '@/assets/icons/cloud.svg';

  export default {
    components: {
      CloudIcon
    },
    props: {
      accept: {
        type: String
      }
    },
    methods: {
      checkFile(e) {
        const inputElement = document.querySelector('#upload-button');
        const inputFile = e.target.files[0];
        const type = inputFile.type.split('/').at(-1);
        const allowedTypes = ['png', 'jpeg'];
        const isAllowedType = allowedTypes.includes(type);

        if (!inputFile || !isAllowedType) {
          inputElement.value = '';
          return e.preventDefault();
        }

        const convertionBase = 1024;
        const fileSizeKB = inputFile.size / convertionBase;
        const maxKB = 800;

        if (fileSizeKB > maxKB) {
          inputElement.value = '';
          this.$emit('openErrorPopup');
          return e.preventDefault();
        }
        const file = new FileReader();
        file.onload = (e) => this.$emit('setImage', e.target.result);
        file.readAsDataURL(inputFile);
        this.$emit('closeErrorPopup');
      }
    }
  };
</script>

<style lang="scss" scoped>
  input {
    all: unset;
  }

  .upload-component {
    width: 100%;
    height: unset;
  }

  .upload {
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #cfc4be;
    border-style: dashed;

    cursor: pointer;

    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;

    margin: 0;
    height: 112px;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
    }
  }

  .cloud-icon {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 768px) {
    .upload {
      height: 140px;
    }
  }
</style>
