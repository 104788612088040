<template>
  <ul id="rtp__status-items">
    <StatusItem
      rule-description="Deve conter 4 números"
      :rule-status="_ruleStatus1"
    />
    <StatusItem
      rule-description="Não deve ser uma sequência"
      :rule-status="_ruleStatus2"
    />
    <StatusItem
      rule-description="Os PINs devem ser iguais"
      :rule-status="_ruleStatus3"
    />
  </ul>
</template>

<script>
  import resetPinMixin from '@/mixins/reset-pin';
  import StatusItem from './components/status-item';

  export default {
    name: 'PinStatus',
    mixins: [resetPinMixin],
    components: {
      StatusItem
    }
  };
</script>

<style lang="scss" scoped>
  #rtp__status-items {
    margin: 8px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 4px 0;
  }

  @media (max-width: 485px) {
    #rtp__status-items {
      gap: 0 !important;
    }
  }
</style>
