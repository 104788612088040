<template>
  <div id="my-account">
    <ForgotPasswordModal
      v-if="showForgotPasswordModal"
      @close="closeModal('password')"
    />
    <ForgotPinModal
      v-if="showForgotPinModal"
      @close="closeModal('pin')"
    />

    <header>
      <h1>{{ $t('Account.Title') }}</h1>
      <hr />
    </header>

    <main>
      <PhotoForm ref="photoForm" />
      <hr />
      <ProfileForm />
      <hr />
      <PasswordResetForm
        ref="passwordForm"
        @openModal="openModal('password')"
      />
      <hr />
      <PINResetForm
        ref="pinForm"
        @openModal="openModal('pin')"
      />
      <SaveChangesModal
        v-if="showSaveModal"
        @undo="discardModifications"
        @save="save"
      />
    </main>
  </div>
</template>

<script>
  import ForgotPasswordModal from './components/forgot-password-modal';
  import ForgotPinModal from './components/forgot-pin-modal';
  import PhotoForm from '@/views/account/PhotoForm.vue';
  import ProfileForm from '@/views/account/ProfileForm.vue';
  import PasswordResetForm from '@/views/account/PasswordResetForm.vue';
  import PINResetForm from '@/views/account/PINResetForm.vue';
  import SaveChangesModal from '@/views/account/components/SaveChangesModal.vue';

  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'AccountPage',
    components: {
      ForgotPasswordModal,
      ForgotPinModal,
      PhotoForm,
      ProfileForm,
      PasswordResetForm,
      PINResetForm,
      SaveChangesModal
    },
    data: () => ({
      showForgotPasswordModal: false,
      showForgotPinModal: false,
      shouldShowSave: false
    }),
    computed: {
      ...mapGetters('accountStore', [
        'currentPassword',
        'newPassword',
        'confirmNewPassword',
        'currentPIN',
        'newPIN',
        'confirmNewPIN',
        'image'
      ]),
      ...mapGetters('userStore', ['user']),
      showSaveModal() {
        const {
          currentPassword,
          newPassword,
          confirmNewPassword,
          currentPIN,
          newPIN,
          confirmNewPIN,
          image
        } = this;

        const hasFilledPassword = currentPassword && newPassword && confirmNewPassword;
        const hasAnyPassFieldDifferent =
          currentPassword !== newPassword || currentPassword !== confirmNewPassword;

        const hasFilledPIN = currentPIN && newPIN && confirmNewPIN;
        const hasAnyPINFieldDifferent = currentPIN !== newPIN || currentPIN !== confirmNewPIN;

        const hasImageUploaded = !!image;

        const shouldShow =
          (hasFilledPassword && hasAnyPassFieldDifferent) ||
          (hasFilledPIN && hasAnyPINFieldDifferent) ||
          hasImageUploaded;

        return shouldShow;
      }
    },
    methods: {
      ...mapActions('accountStore', ['clearAll']),
      ...mapActions('userStore', ['setUser']),
      closeModal(modal) {
        if (modal === 'password') {
          this.showForgotPasswordModal = false;
          return;
        }

        this.showForgotPinModal = false;
      },
      openModal(modal) {
        if (modal === 'password') {
          this.showForgotPasswordModal = true;
          return;
        }

        this.showForgotPinModal = true;
      },
      discardModifications() {
        this.clearAll();
        this.$refs.pinForm.setError('');
        this.$refs.passwordForm.setError('');
        this.$refs.photoForm.closeErrorPopup();
        document.querySelector('#upload-button').value = '';
      },
      validate() {
        const passwordForm = this.$refs.passwordForm.validate();
        const pinForm = this.$refs.pinForm.validate();
        if (passwordForm.valid && pinForm.valid) {
          return { passwordForm, pinForm };
        }
        return false;
      },
      async save() {
        const validForms = this.validate();
        if (!validForms) return;

        const payload = new FormData();

        if (validForms.passwordForm.changed) {
          payload.append('newPasswordValue', this.newPassword);
          payload.append('passwordValueOld', this.currentPassword);
        }

        if (validForms.pinForm.changed) {
          payload.append('oldPin', this.currentPIN);
          payload.append('newPin', this.newPIN);
        }

        const currentPhoto = this.image;
        const originalPhoto = JSON.parse(localStorage.getItem('userData')).photo;

        if (currentPhoto !== originalPhoto && !!currentPhoto) {
          const image = await fetch(currentPhoto).then((res) => res.blob());
          payload.append('photo', image);
        }

        this.updateAccountInformation(payload);
      },
      async updateAccountInformation(payload) {
        const url = `/api/${this._prefix}/users/update`;
        try {
          this._showLoading();
          const response = await this.$http.post(url, payload);
          const user = response.data;
          localStorage.setItem('userData', JSON.stringify(user));
          this.setUser({
            ...user,
            name: `${user.first_name} ${user.last_name}`,
            role: user.job_role,
            picture: user.photo
          });
          this.clearAll();
        } catch (error) {
          this.handleApiError(error);
        } finally {
          this._hideLoading();
        }
      },
      handleApiError(err) {
        const { message: error } = err.response.data;
        if (error === 'Old password does not match') {
          this.$refs.passwordForm.setError('InvalidPass');
        }
        if (error === 'Old pin does not match') {
          this.$refs.pinForm.setError('InvalidPIN');
        }
      }
    }
  };
</script>

<!-- Estilização para evitar repetição em subcomponentes -->
<style lang="scss">
  .account__title-text {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    color: #fff;
    text-align: left;
  }

  .account__subtitle-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #cfc4be;
    text-align: left;
  }
</style>

<style lang="scss" scoped>
  #my-account {
    padding: 20px 29px;
    display: flex;
    flex-direction: column;
    gap: 34px;
    max-width: 1059px;
    margin: 0 auto;

    &:has(.error-popup-photo) {
      gap: 0px;
    }

    header {
      h1 {
        margin: 0;
        font-size: 28px;
        font-weight: 600;
        line-height: 38px;
        text-align: left;
        color: #ffffff;

        + hr {
          height: 1px;
          background: #d9d9d9;
          margin: 4px 0 0 0;
        }
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px 0;

    hr {
      margin: 0;
      width: 100%;
      height: 1px;
      background: #4c4541;
    }

    &:has(#save-changes-modal) {
      margin-bottom: 70px;
    }
  }

  @media (max-width: 1002px) {
    #my-account {
      gap: 0;
    }

    main:has(#save-changes-modal) {
      margin-bottom: 70px;
    }
  }

  @media (max-width: 480px) {
    h1 {
      font-size: 22px !important;
      line-height: 30px !important;
    }

    #my-account {
      padding: 20px 16px !important;
    }

    main {
      gap: 24px !important;

      &:has(#save-changes-modal) {
        margin-bottom: 100px;
      }
    }
  }
</style>
