<template>
  <li
    :class="[
      'rtp__status-item',
      {
        '--check': ruleStatus
      }
    ]"
  >
    <span class="rtp__item-icon-c">
      <span
        class="rtp__item-icon"
        v-show="ruleStatus"
      >
        <CheckIcon />
      </span>
      <span
        class="rtp__item-point"
        v-show="!ruleStatus"
      >
        •
      </span>
    </span>
    <span class="rtp__item-text">
      {{ ruleDescription }}
    </span>
  </li>
</template>

<script>
  import CheckIcon from '@/assets/icons/check-status.svg';

  export default {
    name: 'StatusItem',
    components: {
      CheckIcon
    },
    props: {
      ruleStatus: {
        type: Boolean,
        default: false,
        required: true
      },
      ruleDescription: {
        type: String,
        default: '',
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  .rtp__status-item {
    display: flex;
    flex-wrap: nowrap;

    &.--check {
      .rtp__item-text {
        color: #4caf50;
      }
    }

    .rtp__item-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #998f8a;
      text-align: left;
      padding: 0 8px;
    }

    .rtp__item-icon-c {
      min-width: 12px;
      width: 12px;
      height: 22px;

      display: flex;
      align-items: center;
      justify-content: center;

      .rtp__item-point {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #998f8a;
      }

      .rtp__item-icon {
        margin-top: -2px;
      }

      svg {
        min-width: 12px;
        width: 12px;
        height: 12px;
      }
    }
  }

  @media (max-width: 485px) {
    .rtp__status-item {
      .rtp__item-text {
        font-size: 12px !important;
        line-height: 16px !important;
      }

      .rtp__item-icon-c {
        min-width: 8px !important;
        width: 8px !important;
        height: 16px !important;

        .rtp__item-point {
          font-size: 12px !important;
          line-height: 16px !important;
        }

        svg {
          min-width: 8px !important;
          width: 8px !important;
          height: 8px !important;
        }
      }
    }
  }
</style>
