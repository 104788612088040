<template>
  <div id="password-reset-form">
    <div class="title">
      <h3 class="account__title-text">
        {{ $t('Account.PasswordReset.Title') }}
      </h3>
      <h4 class="account__subtitle-text">
        {{ $t('Account.PasswordReset.Description') }}
      </h4>
      <ul>
        <ValidationItem
          :text="$t('Account.PasswordReset.Rules.MinLength')"
          :valid="minSixChars"
        />
        <ValidationItem
          :text="$t('Account.PasswordReset.Rules.MustHaveNumber')"
          :valid="atLeastOneNumber"
        />
        <ValidationItem
          :text="$t('Account.PasswordReset.Rules.SpecialCharRequired')"
          :valid="atLeastOneSpecialChar"
        />
        <ValidationItem
          :text="$t('Account.PasswordReset.Rules.EqualPasswords')"
          :valid="passwordsAreEqual"
        />
      </ul>
      <Button
        custom-class="forgot-password-btn"
        @click="openModal"
      >
        {{ $t('Account.PasswordReset.ForgotPassword') }}</Button
      >
    </div>
    <div class="form">
      <ErrorPopup
        v-if="error && (error === 'RequiredSteps' ? !completedAllRequirements : true)"
        custom-class="error-popup"
      >
        {{ $t(`Account.Errors.${error}`) }}</ErrorPopup
      >
      <Input
        id="current-password"
        :model="currentPassword"
        :label="$t('Account.PasswordReset.Fields.CurrentPassword.Label')"
        type="password"
        :placeholder="$t('Account.PasswordReset.Fields.CurrentPassword.Placeholder')"
        can-show-password
        :error="error === 'InvalidPass'"
        @update:model="setCurrentPassword"
      />
      <div class="form-fields">
        <Input
          id="new-password"
          :model="newPassword"
          :label="$t('Account.PasswordReset.Fields.NewPassword.Label')"
          type="password"
          :placeholder="$t('Account.PasswordReset.Fields.NewPassword.Placeholder')"
          :error="error === 'RequiredSteps' && !completedAllRequirements"
          @update:model="setNewPassword"
        />
        <Input
          id="confirm-new-password"
          :model="confirmNewPassword"
          :label="$t('Account.PasswordReset.Fields.ConfirmNewPassword.Label')"
          type="password"
          :placeholder="$t('Account.PasswordReset.Fields.ConfirmNewPassword.Placeholder')"
          :error="error === 'RequiredSteps' && !completedAllRequirements"
          @update:model="setConfirmNewPassword"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Input from '@/views/account/components/Input.vue';
  import ErrorPopup from '@/views/account/components/ErrorPopup.vue';
  import ValidationItem from '@/views/account/components/ValidationItem.vue';
  import Button from '@/views/account/components/Button.vue';
  import { mapActions, mapGetters } from 'vuex';
  export default {
    components: {
      ValidationItem,
      Button,
      Input,
      ErrorPopup
    },
    data: () => {
      return {
        error: ''
      };
    },
    computed: {
      ...mapGetters('accountStore', ['currentPassword', 'newPassword', 'confirmNewPassword']),
      minSixChars() {
        return this?.newPassword?.length >= 6;
      },
      atLeastOneNumber() {
        const { newPassword } = this;
        // eslint-disable-next-line no-useless-escape
        const regex = /\d/;
        return regex.test(newPassword);
      },
      atLeastOneSpecialChar() {
        const { newPassword } = this;
        // eslint-disable-next-line no-useless-escape
        const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        return regex.test(newPassword);
      },
      passwordsAreEqual() {
        const { newPassword, confirmNewPassword } = this;
        return newPassword === confirmNewPassword && !!newPassword;
      },
      completedAllRequirements() {
        const { minSixChars, atLeastOneNumber, atLeastOneSpecialChar, passwordsAreEqual } = this;

        return minSixChars && atLeastOneNumber && atLeastOneSpecialChar && passwordsAreEqual;
      }
    },
    methods: {
      ...mapActions('accountStore', [
        'setCurrentPassword',
        'setNewPassword',
        'setConfirmNewPassword'
      ]),
      openModal() {
        this.$emit('openModal');
      },
      validate() {
        const {
          minSixChars,
          atLeastOneNumber,
          atLeastOneSpecialChar,
          passwordsAreEqual,
          currentPassword,
          newPassword,
          confirmNewPassword
        } = this;
        const isValid =
          minSixChars && atLeastOneNumber && atLeastOneSpecialChar && passwordsAreEqual;

        const filledSomething = [currentPassword, newPassword, confirmNewPassword].some(
          (item) => item
        );

        if (!isValid && filledSomething) {
          this.error = 'RequiredSteps';
          return false;
        }
        this.error = '';

        if (!filledSomething) {
          return { changed: false, valid: true };
        }

        return { changed: true, valid: true };
      },
      setError(error) {
        this.error = error;
      }
    }
  };
</script>

<style lang="scss" scoped>
  h3,
  h4 {
    margin-bottom: 8px;
  }
  .form-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  #password-reset-form {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 30px;
  }

  .title {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ul {
    margin-bottom: 24px;
  }

  .form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .forgot-password-btn {
    max-width: max-content;
    max-height: 28px;
  }

  .error-popup {
    max-height: 58px;
  }

  @media (max-width: 1002px) {
    #password-reset-form {
      flex-wrap: wrap;
      gap: 24px;
    }

    .title {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }

    h4 {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .form-fields {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
</style>
