<template>
  <main id="forgot-page-container">
    <ForgotCard v-if="_flagShowForgotCard" />

    <NotificationCard
      v-if="_flagShowSentLinkCardWithButton"
      :card-icon="notification.sentLink.icon"
      :card-title="notification.sentLink.title"
      :card-subtitle="notification.sentLink.subtitle"
      :button-text="notification.sentLink.buttonText"
      :button-event="redirectToLoginPage"
    />
  </main>
</template>

<script>
  import ForgotCard from './components/forgot-card';
  import NotificationCard from '@/views/authentication/components/notification-card';

  import forgotMixin from '@/mixins/forgot';

  export default {
    name: 'ForgotView',
    components: {
      ForgotCard,
      NotificationCard
    },
    mixins: [forgotMixin],
    data: () => ({
      notification: {
        sentLink: {
          icon: 'check',
          title: 'Link enviado',
          subtitle: 'Verifique seu e-mail para acessar o link e restaurar sua senha',
          buttonText: 'Voltar ao Login'
        }
      }
    }),
    destroyed() {
      this._clearForgotStore();
    },
    methods: {
      redirectToLoginPage() {
        this._resetForgotStore();
        this.$router.push({ name: 'login' }).catch(() => {});
      }
    }
  };
</script>

<style lang="scss" scoped>
  #forgot-page-container {
    min-height: 100dvh;
    width: 100%;
    padding: 37px 18px;
    background-image: url('../../../assets/images/cubos.png');
    background-color: #362f2b;
    background-size: 175px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
