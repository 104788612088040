<template>
  <CardLayout>
    <NotificationCardHeader
      :card-icon="cardIcon"
      :card-title="cardTitle"
      :card-subtitle="cardSubtitle"
    />
    <ButtonFullWidth @click="buttonEvent">
      <div slot="text">{{ buttonText }}</div>
    </ButtonFullWidth>
  </CardLayout>
</template>

<script>
  import CardLayout from '../card-layout';
  import NotificationCardHeader from './components/header';
  import ButtonFullWidth from '@/components/buttons/button-full-width';

  export default {
    name: 'NotificationCard',
    components: {
      CardLayout,
      NotificationCardHeader,
      ButtonFullWidth
    },
    props: {
      cardIcon: {
        type: String,
        default: ''
      },
      cardTitle: {
        type: String,
        default: '',
        required: true
      },
      cardSubtitle: {
        type: String,
        default: '',
        required: true
      },
      buttonText: {
        type: String,
        default: '',
        required: true
      },
      buttonEvent: {
        type: Function,
        default: () => {},
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped></style>
