<template>
  <header id="lgc__header-c">
    <CardLayoutLogo />
    <CardLayoutTitle> Entre na sua conta </CardLayoutTitle>
    <CardLayoutSubtitle>
      Por favor, insira seu e-mail e senha para acessar a conta
    </CardLayoutSubtitle>
  </header>
</template>

<script>
  import CardLayoutLogo from '@/views/authentication/components/card-layout/components/indsiders-logo';
  import CardLayoutTitle from '@/views/authentication/components/card-layout/components/title';
  import CardLayoutSubtitle from '@/views/authentication/components/card-layout/components/subtitle';

  export default {
    name: 'LoginCardHeader',
    components: {
      CardLayoutLogo,
      CardLayoutTitle,
      CardLayoutSubtitle
    }
  };
</script>

<style lang="scss" scoped>
  #lgc__header-c {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
</style>
