<template>
  <button
    type="button"
    @click="$emit('click')"
    :class="[
      'tmf__button',
      {
        'tmf__cancel-button': buttonType === 'cancel',
        'tmf__send-button': buttonType === 'send'
      }
    ]"
  >
    {{ buttonText }}
  </button>
</template>

<script>
  export default {
    name: 'ModalTemplateForgotButton',
    props: {
      buttonText: {
        type: String,
        default: ''
      },
      buttonType: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss" scoped>
  .tmf__button {
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 8px 20px;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.175px;

    display: flex;
    align-items: center;
    justify-content: center;

    outline: none;
    user-select: none;

    &.tmf__cancel-button {
      background: transparent;
      border-color: #ef5350;
      color: #ef5350;

      &:hover {
        border-color: #ffcdd2;
        color: #ffcdd2;
      }

      &:active {
        border-color: #e53935;
        color: #e53935;
      }
    }

    &.tmf__send-button {
      background: #974900;
      border-color: #974900;
      color: #fff;

      &:hover {
        background: #9f5714;
      }

      &:active {
        background: #a45f1f;
      }
    }
  }
</style>
