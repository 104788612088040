<template>
  <div style="all: unset">
    <div
      id="menu-bar"
      :class="[isOpen ? 'open' : '', wide ? 'wide' : '']"
    >
      <div class="content">
        <!-- Logo -->
        <div :class="['logo-container', isOpen ? 'logo-full' : 'logo']">
          <LogoFull
            v-if="isOpen"
            class="logo-full-icon"
          />
          <LogoShort
            v-else
            class="logo-short-icon"
          />
          <button
            v-if="wide"
            class="menu-full-close-btn"
            @click="$emit('close')"
          >
            <div class="close-icon">
              <CloseIcon
                class="close-icon"
                :style="{ width: '12px', height: '12px' }"
              />
            </div>
          </button>
        </div>

        <!-- User information -->
        <div class="user-info">
          <Profile
            :variant="variation"
            :user="user"
            :title="$t('Menu.Titles.Account')"
            @close="$emit('close')"
          />
        </div>

        <!-- General -->
        <NavItemGroup
          ref="generalGroup"
          :title="$t('Menu.Groups.General')"
          :variant="variation"
        >
          <NavItem
            :name="$t('Menu.Items.Home')"
            :variant="variation"
            :selected="currentPage === 'home'"
            :icon="icons.HomeIcon"
            @click="() => redirect('home')"
          />

          <LanguageSelector :variant="variation" />
        </NavItemGroup>

        <!-- Help -->
        <NavItemGroup
          ref="helpGroup"
          :title="$t('Menu.Groups.Help')"
          :border="false"
          :variant="variation"
        >
          <NavItem
            :name="$t('Menu.Items.Support')"
            :variant="variation"
            :selected="currentPage === 'support'"
            :icon="icons.HeadsetIcon"
            @click="() => redirect('support')"
          />
        </NavItemGroup>
      </div>
      <SiteSelector
        :variant="variation"
        :device="device"
        origin="sidebar"
      />

      <!-- Toggle Sidebar Button -->
    </div>
    <button
      v-if="!wide"
      class="toggle-sidebar"
      @click="toggleSidebar"
    >
      <ArrowLeft
        style="{width: '12px', height: '12px'}"
        :class="['toggle-icon', isOpen ? 'open' : 'closed']"
      />
    </button>
  </div>
</template>

<script>
  // Icons
  import ArrowLeft from '@/assets/icons/arrow-left.svg';
  import LogoFull from '@/assets/icons/logo-full.svg';
  import LogoShort from '@/assets/icons/logo-short.svg';
  import HomeIcon from '@/assets/icons/home-icon.svg';
  import HeadsetIcon from '@/assets/icons/headset-icon.svg';
  import CloseIcon from '@/assets/icons/x-icon.svg';

  // Components
  import Profile from '@/components/Menu/Profile/index.vue';
  import NavItem from '@/components/Menu/NavItem.vue';
  import NavItemGroup from '@/components/Menu/NavItemGroup.vue';
  import LanguageSelector from '@/components/Menu/LanguageSelector/index.vue';

  import SiteSelector from '@/components/Menu/SiteSelector.vue';

  import { mapGetters } from 'vuex';

  const ICONS = {
    HomeIcon,
    HeadsetIcon
  };

  export default {
    name: 'MenuBar',
    components: {
      // Icons
      ArrowLeft,
      LogoFull,
      LogoShort,
      CloseIcon,

      // Components
      Profile,
      NavItem,
      NavItemGroup,
      LanguageSelector,
      SiteSelector
    },
    props: {
      wide: {
        type: Boolean,
        default: false
      },
      device: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpen: this?.wide ? true : false,
        icons: ICONS
      };
    },
    computed: {
      ...mapGetters('userStore', ['user']),
      variation() {
        return this.isOpen ? 'full' : 'short';
      },
      currentPage() {
        return this.$route.name;
      },
      language() {
        return this.$i18n.locale;
      }
    },
    methods: {
      toggleSidebar() {
        this.isOpen = !this.isOpen;
      },
      redirect(page) {
        if (this.wide) {
          this.$emit('close');
        }
        if (this.$route.name === page) return;

        this.$router.push({ name: page }).catch(() => {});
      }
    }
  };
</script>

<style lang="scss">
  .wide {
    .user {
      strong,
      span {
        max-width: 100% !important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .close-icon {
    width: 12px;
    height: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-full-close-btn {
    outline: 0;
    border: 1px solid transparent;
    background: transparent;
    cursor: pointer;

    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    padding: 0;
    border: 1px solid #4c4541;
    background: #1a1c19;

    &:hover {
      border: 1px solid #974900;
    }

    &:active {
      border: 1px solid #e1730b;
    }
  }
  .toggle-icon {
    width: 12px;
    height: 12px;

    &.closed {
      transform: rotate(180deg);
    }
  }

  .logo-full-icon {
    width: 134px;
    height: 20px;
  }

  .logo-short-icon {
    width: 44.8px;
    height: 40px;
  }

  #menu-bar {
    width: 98px;
    height: 100vh;
    padding: 16px 0 0;

    z-index: 2;

    transition: all 0.6s;

    border-right: 1px solid #4c4541;
    background: #1a1c1980;
    backdrop-filter: blur(25px);

    display: flex;
    flex-direction: column;

    justify-content: space-between;
    gap: 16px;

    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    &.open,
    &.wide {
      overflow-y: scroll;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-full {
    padding: 23px 16px;
    text-align: left;
  }

  .user-info {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
  }

  .toggle-sidebar {
    width: 24px;
    height: 24px;

    justify-content: center;
    align-items: center;
    gap: 10px;
    outline: 0;

    position: absolute;
    right: -12px;
    top: 24px;

    border-radius: 50%;
    padding: 0;
    border: 1px solid #4c4541;
    background: #1a1c19;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 2;

    &:hover {
      border: 1px solid #974900;
    }

    &:active {
      border: 1px solid #e1730b;
    }

    span {
      color: white;
    }

    cursor: pointer;
  }

  .open {
    width: 250px !important;
    gap: 12px !important;
  }

  .wide {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 5 !important;
    width: 100vw !important;
    overflow-y: auto !important;

    border-right: 1px solid #4c4541 !important;
    background: rgba(26, 28, 25, 0.5) !important;
    -webkit-backdrop-filter: blur(25px) !important;
    backdrop-filter: blur(25px) !important;

    height: 100dvh !important;

    + div {
      overflow: hidden !important;
    }
  }
</style>
