<template>
  <LoginPageLayoutCard>
    <ForgotCardHeader />
    <ForgotCardForm />
  </LoginPageLayoutCard>
</template>

<script>
  import LoginPageLayoutCard from '@/views/authentication/components/card-layout';
  import ForgotCardHeader from './components/header';
  import ForgotCardForm from './components/form';

  export default {
    name: 'ForgotCard',
    components: {
      LoginPageLayoutCard,
      ForgotCardHeader,
      ForgotCardForm
    }
  };
</script>

<style lang="scss" scoped></style>
