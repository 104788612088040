import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/home/';
import LoginView from '@/views/authentication/login';
import LogoutView from '@/views/logout';
import ForgotView from '@/views/authentication/forgot';
import SupportView from '../views/support';
import AccountView from '../views/account';
import ResetPasswordView from '@/views/authentication/reset-password';
import ResetPinView from '@/views/authentication/reset-pin';
import store from '@/store';

import { isUserLoggedIn } from '@/auth/utils';
// import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter);

const routes = [
  {
    path: '/:prefix',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/multiple-account',
    name: 'multiple-account',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgotView
  },
  {
    path: '/:prefix/reset-password/:token',
    name: 'reset-password',
    component: ResetPasswordView
  },
  {
    path: '/:prefix/reset-pin/:token',
    name: 'reset-pin',
    component: ResetPinView
  },
  {
    path: '/:prefix/support',
    name: 'support',
    component: SupportView
  },
  {
    path: '/:prefix/account',
    name: 'account',
    component: AccountView
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, _, next) => {
    const { name, path } = to;
    const isLoggedIn = isUserLoggedIn();
    const authenticationPages = ['login', 'forgot', 'multiple-account'];
    const resetPages = ['reset-password', 'reset-pin'];
    const isResetPage = resetPages.includes(name);
    let authenticationPage = '';
    const isAuthenticationPage = authenticationPages.some((page) => {
      if (path.includes(page)) {
        authenticationPage = page;
        return true;
      }
    });

    const prefix = localStorage.getItem('prefix') || to.params.prefix;

    if (name === 'home' && isAuthenticationPage) return next({ name: authenticationPage });
    if (isLoggedIn && to.params.prefix && to.params.prefix !== localStorage.getItem('prefix'))
      return next({
        name: 'home',
        params: { prefix: localStorage.getItem('prefix') }
      });

    if (!name) return next({ name: 'home', params: { prefix: prefix } });
    if (!isLoggedIn && !isAuthenticationPage && !isResetPage) return next({ name: 'login' });
    if (isLoggedIn && isAuthenticationPage && name !== 'multiple-account') {
      return next({ name: 'home', params: { prefix: prefix } });
    }

    store.commit('appStore/UPDATE_PREFIX', prefix);
    return next();
});

export default router;
