<template>
  <div
    class="checkbox__wrapper"
    @click="$emit('update:checkboxValue', !checkboxValue)"
  >
    <div :class="['checkbox__container', { checkbox__active: checkboxValue }]">
      <CheckIcon
        v-show="checkboxValue"
        class="checkbox__icon"
      />
    </div>
    <span class="checkbox__text">
      {{ checkboxText }}
    </span>
  </div>
</template>

<script>
  import CheckIcon from '@/assets/icons/check.svg';

  export default {
    name: 'CheckboxComponent',
    components: {
      CheckIcon
    },
    props: {
      checkboxValue: {
        type: Boolean,
        default: false,
        required: true
      },
      checkboxText: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss" scoped>
  .checkbox__wrapper {
    display: flex;
    align-items: center;
    gap: 0 5px;
    user-select: none;
    cursor: pointer;

    .checkbox__container {
      width: 18px;
      height: 18px;
      min-width: 18px;
      max-width: 18px;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 3px;
      border: 1px solid #cfc4be;

      &.checkbox__active {
        background: #974900;
      }

      .checkbox__icon {
        width: 12px;
        height: 12px;
        user-select: none;
      }
    }

    .checkbox__text {
      color: #4c4541;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  @media (max-width: 485px) {
    .checkbox__wrapper {
      .checkbox__container {
        width: 12px !important;
        height: 12px !important;
        min-width: 12px !important;
        max-width: 12px !important;

        .checkbox__icon {
          width: 6px !important;
          height: 6px !important;
        }
      }

      .checkbox__text {
        font-size: 12px !important;
        line-height: 16px !important;
      }
    }
  }
</style>
