<template>
  <div class="rlc__icon-c">
    <CheckIcon
      v-if="icon === 'check'"
      class="rlc__check-icon"
    />
    <AlertIcon
      v-if="icon === 'alert'"
      class="rlc__alert-icon"
    />
  </div>
</template>

<script>
  import AlertIcon from '@/assets/icons/alert-login-card.svg';
  import CheckIcon from '@/assets/icons/check-login-card.svg';

  export default {
    name: 'ResentLinkCardIcon',
    components: {
      CheckIcon,
      AlertIcon
    },
    props: {
      icon: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss" scoped>
  .rlc__icon-c {
    svg {
      width: 60px;
      height: 60px;
    }
  }
</style>
